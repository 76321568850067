import {NgModule} from '@angular/core';
import {ButtonComponent} from './item';
import {CommonModule} from '@angular/common';
import {ButtonModule as PrimeButton} from 'primeng/button';

@NgModule({
  imports: [
    CommonModule,
    PrimeButton
  ],
  exports: [
    ButtonComponent
  ],
  declarations: [ButtonComponent],
  providers: [],
})
export class ButtonModule {
}
