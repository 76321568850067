import {formatDate} from '@angular/common';
import {HelperService} from '@/app/services/helper.service';
import { environment } from '@/environments/environment';

export class Entity {

  constructor(data: Partial<any> = null) {
    Object.assign(this, data);
  }

  getFormattedDate ?(date: string, format = 'dd.MM.yyyy') {
    try {
      return date ? formatDate(date, format, 'en-EN') : '';
    } catch (e) {
      console.error(e);

      return '';
    }
  }

  getUrl ?(url: string) {
    if (url) {

      if (HelperService.validURL(url) || HelperService.validBlob(url)) {
        return url;
      } else {
        return environment.baseUrl + environment.publicUrl + url.replace(/^\/+/, '');
      }

    } else {
      return null;
    }
  }

  getDownloadUrl ?(url: string) {
    return environment.baseUrl + environment.downloadUrl + url.replace(/^\/+/, '');
  }

  getFormattedPrice ?(price: any) {
    return HelperService.getFormattedPrice(price);
  }

}
