import {AbstractControl, FormControl, FormGroup, ValidationErrors} from '@angular/forms';
import {HelperService} from '../../services/helper.service';
import {isAfter} from 'date-fns';
import {FormElementValidation} from '@/app/entities/form/form-element.entity';

export const lessThen = (relation, text) => (control: AbstractControl): ValidationErrors | null => {
  const val = control.value;
  const relatedControl = control.parent?.get(relation);
  const relatedVal = relatedControl?.value;

  if (relatedVal && val > relatedVal) {
    return {
      lessThen: text
    };
  }

  return {};
};

export const biggerThen = (relation, text) => (control: AbstractControl): ValidationErrors | null => {
  const val = control.value;
  const relatedControl = control.parent?.get(relation);
  const relatedVal = relatedControl?.value;

  if (relatedVal && val < relatedVal) {
    return {
      biggerThen: text
    };
  }

  return {};
};

export const dateLessThan = (required = false) => (control: AbstractControl): ValidationErrors | null => {
  const val = control.value;


  if (required) {
    if (val === null || val?.[0] === '' || val?.[0] === null || val?.[1] === '' || val?.[1] === null) {
      return {
        required: 'Обязательное поле'
      };
    }
  }

  if (val && val[0] && val[1]) {

    const before = HelperService.strToDate(val[0]);
    const after = HelperService.strToDate(val[1]);

    if (isAfter(before, after)) {
      return {
        range: 'Неверный интервал'
      };
    }
  }

  return {};
};

export const sameAs = (validators: FormElementValidation): ValidationErrors | null => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control instanceof FormGroup) {
      const from = control.controls[validators.relationFrom] as AbstractControl;
      const to = control.controls[validators.relation] as AbstractControl;
      if (from && to && from.value && to.value && from.value !== to.value) {
        from.setErrors({ sameAs: 'Не совпадает с '+ to['field']().elementConfiguration.label })
        if ((from as any).captions?.length) {
          const equalIdx = (from as any).captions.findIndex(item => item.type === 'equal');
          (from as any).captions.splice(equalIdx, 1)
        }
      }
      return null;
    } else {
      const c = (control.parent?.controls as any)[validators.relation] as AbstractControl;
      return !!control?.parent && control.value && c.value
        && control.value === c.value
          ? null
          : { sameAs: 'Не совпадает с '+ c['field']().elementConfiguration.label };
    }
  };
}

export const equal = (validators: FormElementValidation): ValidationErrors | null => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control instanceof FormGroup) {
      const from = control.controls[validators.relationFrom] as AbstractControl;
      const to = control.controls[validators.relation] as AbstractControl;
      if (from && to && from.value && to.value && from.value === to.value) {
        from.setErrors({ equal: 'Cовпадает с '+ to['field']().elementConfiguration.label })
      }
      return null;
    } else {
      const c = (control.parent?.controls as any)[validators.relation] as AbstractControl;
      return !!control?.parent && control.value && c.value && control.value === c.value
        ? { equal: 'Cовпадает с '+ c['field']().elementConfiguration.label }
        : null
    }
  };
}

export const slug = (): ValidationErrors | null => {
  return (control: AbstractControl): ValidationErrors | null => {
    const val = control.value;
    if (typeof val === 'string' && !(/^[\w\d-]{0,20}$/.test(val))) {
      return {slug: 'Не верно указаный слаг'}
    }
    return null;
  };
}
