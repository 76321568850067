import {Component, ElementRef, Input, OnChanges, ViewChild, ViewEncapsulation} from '@angular/core';
import {HttpClient} from '@angular/common/http';

/**
 * Configurable params:
 * - iconName             - название файла иконки
 * - outputSvg            - (optional) отображать как svg (иначе как img)
 * - customStrokeColor    - (optional) если outputSvg: true, то динамически задаст кастомный цвет параметру stroke внутри svg
 * - customFillColor      - (optional) если outputSvg: true, то динамически задаст кастомный цвет параметру fill внутри svg
 *
 *  Usage:
 * ```
 *    <app-icon
 *      [iconName]="route.data.menuIcon"
 *      [outputSvg]="true"
 *      [customStrokeColor]="customColor(route)"
 *    ></app-icon>
 * ```
 */

@Component({
  selector: 'app-icon',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  encapsulation: ViewEncapsulation.None
})


export class IconComponent implements OnChanges {
  @ViewChild('svgContainer', {static: false}) svgContainer: ElementRef;

  @Input() public iconName?: string;
  @Input() public outputSvg?: boolean = false;
  @Input() public customStrokeColor?: string = null;
  @Input() public customFillColor?: string = null;

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public ngOnChanges(): void {
    if (!this.iconName) {
      return;
    }

    if (this.outputSvg) {
      this.httpClient
        .get(this.getIconPath(), {responseType: 'text'})
        .subscribe(value => {
          this.svgContainer.nativeElement.innerHTML = value;

          this.svgContainer?.nativeElement.querySelectorAll('path').forEach((item) => {
            if (this.customStrokeColor) {
              item.setAttribute('style', 'stroke: ' + this.customStrokeColor);
            }
            if (this.customFillColor) {
              item.setAttribute('style', 'fill: ' + this.customFillColor);
            }
          });
        });
    }
  }

  getIconPath() {
    return `/assets/images/icons/${this.iconName}.svg`;
  }
}
