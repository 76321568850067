<span
  #svgContainer
  *ngIf="outputSvg"
  class="icon-span-container"
></span>

<span
  *ngIf="!outputSvg"
  class="icon-span-container"
>
  <img [src]="getIconPath()" [alt]="iconName">
</span>
