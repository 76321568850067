<div class="floated">
  <label class="floated-label" [class.floated-active]="selected || selected?.length || isOpen">{{label}}</label>
  <div class="select">
    <div class="select-origin"
         [ngClass]="{
          'multiple-selected': type === 'multiple' && selected?.length,
          'readonly': readonly
       }"
         cdkOverlayOrigin
         #trigger="cdkOverlayOrigin"
         (click)="toggle()"
    >
      <ng-container *ngTemplateOutlet="headerContent"></ng-container>
      <button>
        <i class="pi pi-chevron-down"></i>
      </button>
    </div>
  </div>
</div>


@if (isMobile) {
<!--  @todo all modals and alerts, must be added via some servicec that will add it to history state, so esc and back button will close it -->
  <ng-template
    let-observer
    [tuiSheetDialogOptions]="{
        label: 'label'
    }"
    [(tuiSheetDialog)]="isOpen"
  >
    <div>
      <ng-container *ngTemplateOutlet="dropdownContent"></ng-container>
    </div>
  </ng-template>
} @else {
  <ng-template cdkConnectedOverlay
               [cdkConnectedOverlayOrigin]="trigger"
               [cdkConnectedOverlayOpen]="isOpen"
               [cdkConnectedOverlayHasBackdrop]="true"
               [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
               [cdkConnectedOverlayPositions]="positions"
               [cdkConnectedOverlayWidth]="originWidth"
               [cdkConnectedOverlayFlexibleDimensions]="true"
               cdkConnectedOverlayBackdropClass="select-backdrop"
               cdkConnectedOverlayPanelClass="select-overlay"
               (backdropClick)="overlayWrapper.close()"
               (detach)="overlayWrapper.close()"
               (positionChange)="onPositionChange($event)">

    <div (click)="overlayWrapper.close()" class="select-origin focus"
         [ngClass]="{
        'multiple-selected': type === 'multiple' && selected?.length,
        'readonly': readonly
      }"
    >
      <ng-container *ngTemplateOutlet="headerContent"></ng-container>
      <button>
        <i class="pi pi-chevron-up"></i>
      </button>
    </div>
    <app-select-overlay-wrapper #overlayWrapper (onClose)="onClose()">
      <div class="p-dropdown-panel select-dropdown">
        <ng-container *ngTemplateOutlet="dropdownContent"></ng-container>
      </div>
    </app-select-overlay-wrapper>
  </ng-template>
}

<ng-template #headerContent>
  <div class="select-origin-content">
    @if (type === 'single') {
      <div class="select-origin-content-selected">
        @if (selected) {
          @if(selectHeader) {
            <ng-container *ngTemplateOutlet="selectHeader; context: {node: selected}"></ng-container>
          } @else {
            {{getLabel(selected)}}
          }
        }
      </div>

      @if (selected && !readonly && !loading) {
        <button class="select-origin-content-remove" (click)="removeSingle($event)">
          <i class="pi pi-times"></i>
        </button>
      }

    } @else {
      @for(item of selected; track item.key) {
        <p-chip [label]="getLabel(item)" [removable]="!readonly" (onRemove)="remove($event, item)">
          @if(selectHeader) {
            <ng-container *ngTemplateOutlet="selectHeader; context: {node: item}"></ng-container>
          }
        </p-chip>
      }
    }

    @if (loading) {
      <div class="select-loader flex justify-content-center">
        <p-progressSpinner ariaLabel="loading" styleClass="w-1rem h-1rem"></p-progressSpinner>
      </div>
    }
  </div>
</ng-template>

<ng-template #dropdownContent>
  @if (!isSearchByDefault) {
    <div class="select-dropdown-search p-input-icon-right">
      <i class="pi pi-search"></i>
      <input type="text" pInputText pAutoFocus [autofocus]="true" [formControl]="search" />
    </div>
  }

  <div class="select-dropdown-options selected-dropdown-option-filter"
       [ngClass]="{
        'not-full': totalItems <= 5,
        'has-filter': isSearchByDefault
       }"
       [style]="'--select-elements:' + totalItems"
  >
    <p-tree
        #tree
        [metaKeySelection]="false"
        [value]="treeItems"
        [selectionMode]="type"
        [scrollHeight]="totalItems > 5 ? '200' : undefined"
        [virtualScroll]="totalItems > 5"
        [virtualScrollItemSize]="42"
        [loading]="loading"
        [filter]="isSearchByDefault"
        [(selection)]="selected"
        (selectionChange)="select()"
        (onNodeExpand)="nodeExpand($event)"
    >
      @if (selectOption) {
        <ng-template let-node pTemplate="default">
          <ng-container *ngTemplateOutlet="selectOption; context: {node: node}"></ng-container>
        </ng-template>
      } @else {
        <ng-template let-node pTemplate="default">
          <div class="w-full text-overflow-ellipsis overflow-hidden white-space-nowrap min-w-0">
            {{node.label}}
          </div>
        </ng-template>
      }

      <ng-template let-node pTemplate="loadMore">
        <div class="load-more">
          <app-button [label]="node.label" [text]="true" size="small" (onClick)="onLoadMore.emit()"></app-button>
        </div>
      </ng-template>

      <ng-template pTemplate="empty">
        Ничего не найдено
      </ng-template>
    </p-tree>
  </div>
</ng-template>
