import {Injectable, Provider, WritableSignal, signal} from '@angular/core';
import {PolymorpheusComponent} from '@tinkoff/ng-polymorpheus';
import {AbstractTuiDialogService, TUI_DIALOGS} from '@taiga-ui/cdk';
import {ParentModalComponent} from '@/app/modals/parent-modal/item';
import {ModalEntity} from '@/app/entities/form-modal/form-modal.entity';

@Injectable({
  providedIn: 'root'
})
export class ParentModalService extends AbstractTuiDialogService<ModalEntity<any>, any>{
  readonly defaultOptions: ModalEntity<any> = {} as const;

  readonly component = new PolymorpheusComponent(ParentModalComponent) as any;

  loading: WritableSignal<boolean> = signal(false);
}
export const MODAL_PROVIDER: Provider = {
  provide: TUI_DIALOGS,
  useExisting: ParentModalService,
  multi: true,
};
