import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';
import {TuiDestroyService} from '@taiga-ui/cdk';
import {BaseFormComponent} from '@/app/components/form-components/form-constructor/base/base-form.component';

/**
 * This component is only used in FormGroupComponent to render FormGroups and add new FormGroup
 *
 * @example
 * <app-form-array
 *   [makeAllFormTouched]="false"
 *   [formArray]='control'
 * ></app-form-array>
 */
@Component({
  selector: 'app-form-array',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TuiDestroyService
  ]
})
export class FormArrayComponent extends BaseFormComponent {
  /**
   * FormArray control
   */
  @Input() formArray: FormArray | any;


  /**
   * Return slug for trackBy function on for
   *
   * @param {number} index - The index of the field in the group.
   * @param {FormGroup} group - The form group containing the field.
   * @return {string} - The slug value of the field.
   */
  tracked(index: number, group: FormGroup) {
    return group['field']().slug
  }
}
