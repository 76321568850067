import {NgModule} from '@angular/core';
import {DatePickerComponent} from './item';
import {CommonModule} from '@angular/common';
import {DropdownModule} from 'primeng/dropdown';
import {ReactiveFormsModule} from '@angular/forms';
import {CalendarModule} from 'primeng/calendar';

@NgModule({
  imports: [
    CommonModule,
    DropdownModule,
    ReactiveFormsModule,
    CalendarModule
  ],
  exports: [
    DatePickerComponent
  ],
  declarations: [DatePickerComponent],
  providers: [],
})
export class DatePickerModule {
}
