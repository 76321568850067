import {NgModule} from '@angular/core';
import {OrganizationCardComponent} from './item';
import {CommonModule} from '@angular/common';
import {AvatarModule} from '@/app/components/app-components/avatar/item.module';
import {WorkerCardModule} from '@/app/components/app-components/worker-card/item.module';
import {ButtonModule} from '@/app/components/app-components/button/item.module';
import {DirectivesModule} from '@/app/core/directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    AvatarModule,
    WorkerCardModule,
    ButtonModule,
    DirectivesModule
  ],
  exports: [
    OrganizationCardComponent
  ],
  declarations: [OrganizationCardComponent],
  providers: [],
})
export class OrganizationCardModule {
}
