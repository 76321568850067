import {Type} from 'class-transformer';
import 'reflect-metadata';
import {Entity} from '@/app/core/entity/main.entity';
import {AppColorList} from '@/app/entities/enums';
import {SwitchItem, SwitchItemTitlePrefix} from '@/app/entities/switch/switch.entity';
import { SchemaParser } from '@/app/core/classes/schema-parser';
import { FormElementSelectGqlConfiguration } from '@/app/entities/form/form-element-select.entity';

export class Dictionary extends Entity {
  code?: string;
  id: number | string;

  // @todo refactor to keep one after we will rework Profile section
  name?: string;
  title?: string;

  selectedTitle?: string = null;

  color?: AppColorList | string = null;
  selectedColor?: AppColorList | string = null;
  selectedBackground?: AppColorList | string = null;

  @Type(() => SwitchItemTitlePrefix)
  prefix?: SwitchItemTitlePrefix = null;

  @Type(() => Dictionary)
  items?: Dictionary[] = [];

  // @todo refactor to keep one after we will rework Profile section
  order?: number;
  profile_field_id?: number;

  gqlRequestConfig: FormElementSelectGqlConfiguration;

  getSwitchItem?(gqlRequestConfig?: FormElementSelectGqlConfiguration): SwitchItem {

    this.gqlRequestConfig = gqlRequestConfig;

    const data: Partial<SwitchItem> = {
      id: this.getId(),
      title: this.getTitle()
    };

    if (this.selectedTitle) {
      data.selectedTitle = this.selectedTitle;
    }

    if (this.color) {
      data.color = this.color;
    }

    if (this.selectedColor) {
      data.selectedColor = this.selectedColor;
    }

    if (this.selectedBackground) {
      data.selectedBackground = this.selectedBackground;
    }

    if (this.prefix) {
      data.prefix = this.prefix;
    }

    if (this.items) {
      data.subItems = this.items.map(subItem => subItem.getSwitchItem());
    }

    const item = new SwitchItem(data);
    item.data = {
      code: this.code,
      id: this.getId(),
      name: this.getTitle(),
      title: this.getTitle(),
      order: this.order,
      profile_field_id: this.profile_field_id,
    };

    return item;
  }

  private getTitle?() {
    if (this.gqlRequestConfig) {
      return SchemaParser.interpolate(this.gqlRequestConfig.fieldAsTitleSchema, this);
    } else {
      return this.title ? this.title:this.name;
    }
  }

  private getId?(): string | number {
    if (this.gqlRequestConfig) {
      return this[this.gqlRequestConfig.fieldAsId];
    } else {
      return this.id;
    }
  }
}
