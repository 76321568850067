import {Entity} from '../../core/entity/main.entity';

export class Pagination extends Entity {
  currentPage = 1;
  perPage = 50;
  totalPage = 0;
  total = 0;

  constructor(currentPage: string | number = 1, perPage: string | number = 50, totalPage: string | number = 0, total: string | number = 0) {
    super({});

    this.currentPage = parseInt(currentPage.toString(), 10);
    this.perPage = parseInt(perPage.toString(), 10);
    this.totalPage = parseInt(totalPage.toString(), 10);
    this.total = parseInt(total.toString(), 10);
  }

  get nextPage() {
    return this.currentPage + 1;
  }

  isNextPageExist() {
    return this.currentPage + 1 < this.totalPage;
  }

}
