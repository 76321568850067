import {NgModule} from '@angular/core';
import {InputSearchComponent} from './item';
import {CommonModule} from '@angular/common';
import {InputTextModule} from 'primeng/inputtext';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    InputTextModule,
    ReactiveFormsModule
  ],
  exports: [
    InputSearchComponent
  ],
  declarations: [InputSearchComponent],
  providers: [],
})
export class InputSearchModule {
}
