import {ChangeDetectorRef, Component, Injector, inject, OnInit, HostListener, Inject} from '@angular/core';
import {UserModelService} from '@/app/services/user-model.service';
import {AppService} from '@/app/services/app.service';
import {Router, NavigationExtras} from '@angular/router';
import {ToastService} from '@/app/components/app-components/toast/toast.service';
import {Toast} from '@/app/components/app-components/toast/toast.intefaces';
import {ModalService} from '@/app/services/modals/modal.service';

@Component({template: ''})
export class AbstarctCommonPage implements OnInit {
  isMobile: boolean = false;

  injector: Injector;
  userModel: UserModelService;
  appService: AppService;
  cdr: ChangeDetectorRef;
  toast: ToastService
  modalService: ModalService
  router = inject(Router);

  constructor(
    injector: Injector,
  ) {
    this.injector = injector;
    this.userModel = this.injector.get(UserModelService);
    this.appService = this.injector.get(AppService);
    this.cdr = this.injector.get(ChangeDetectorRef);
    this.toast = this.injector.get(ToastService);
    this.modalService = this.injector.get(ModalService);
  }

  @HostListener('window:resize')
  onResize() {
    this.isMobile = window.innerWidth < 768;
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.onResize()
  }

  goBack() {
    const currentUrl = this.router.url;
  
    const navigationExtras: NavigationExtras = {
      queryParamsHandling: 'preserve',
      state: { fromHistory: true }
    };
  
    if (currentUrl !== '/') {
      const previousUrl = currentUrl.split('/').slice(0, -1).join('/');
      this.router.navigate([previousUrl], navigationExtras);
    }
  }

  showToast(data: Toast) {
    this.toast.add(data)
  }
}
