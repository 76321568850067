<p-button
    #button
    [style]="computedStyle()"
    [styleClass]="styleClass"
    [label]="label"
    [rounded]="true"
    [icon]="icon"
    [iconPos]="iconPosition"
    [size]="size"
    [severity]="severity"
    [loading]="loading"
    [disabled]="disabled"
    [raised]="raised"
    [outlined]="outlined"
    [text]="text"
    (onClick)="onClick.emit($event)"
>
    <ng-content></ng-content>
</p-button>
