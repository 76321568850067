import {NgModule} from '@angular/core';
import {SelectComponent} from './item';
import {CommonModule} from '@angular/common';
import {CdkConnectedOverlay, CdkOverlayOrigin, OverlayModule} from '@angular/cdk/overlay';
import {TreeModule} from 'primeng/tree';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';
import {TuiSheetDialogModule} from '@taiga-ui/addon-mobile';
import {ChipModule} from 'primeng/chip';
import {ButtonModule} from '@/app/components/app-components/button/item.module';
import {AutoFocusModule} from 'primeng/autofocus';
import {SelectOverlayWrapperComponent} from '@/app/components/form-components/select/overlay-wrapper/item';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
  imports: [
    CommonModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    OverlayModule,
    TreeModule,
    FormsModule,
    InputTextModule,
    TuiSheetDialogModule,
    ReactiveFormsModule,
    ButtonModule,
    ChipModule,
    AutoFocusModule,
    ProgressSpinnerModule
  ],
  exports: [
    SelectComponent
  ],
  declarations: [SelectComponent, SelectOverlayWrapperComponent],
  providers: [],
})
export class SelectModule {
}
