import {HelperService} from '@/app/services/helper.service';

export class SchemaParser {

  private static functions: { [funcName: string]: any } = {
    join: (items: any[], separator: string) => items.join(separator),
    date: (strDate: string, format: string = 'dd.MM.yyyy') => HelperService.convertDateToCustomDate(HelperService.strToDate(strDate)).format(format),
    numberFormat: (num: number) => HelperService.formatNumber(num),
    if: (value: any, condition: any, conditionIsTrue: string, conditionIsFalse: string) => value === condition ? conditionIsTrue : conditionIsFalse,
  };

  private static getValue(object: any, path: string): any {
    return path.split('.').reduce((o, k) => (o || {})[k], object);
  }

  private static parseString(input: string): string[] {
    const matches = input.match(/'.+?'/g) || [];
    return matches.map((match) => match.slice(1, match.length - 1));
  }

  public static interpolate(input: string, values: any): string {
    let output = input;

    output = output.replace(/\{:(.*?)\}/g, (match, group) => {
      let [funcName, ...params] = group.split(/\((.*?)\)/).filter(Boolean);
      params = this.parseString(params[0]);
      if (!this.functions[funcName]) {
        throw new Error(`Неизвестная функция форматирования: ${funcName}`);
      }
      const [path, ...otherParams] = params;
      const value = this.getValue(values, path);
      return this.functions[funcName](value, ...otherParams);
    });

    output = output.replace(/\{(.*?)\}/g, (match, group) => {
      const val = this.getValue(values, group);
      return `${val}`;
    });

    return output;
  }
}
