import {Injectable} from '@angular/core';
import {
  format,
  formatISO,
  parseISO,
} from 'date-fns';
import {ru} from 'date-fns/locale';
import {File} from '../entities/file/file.entity';
import {FileGroupTypes} from '../entities/enums';
import {CustomDate} from '../core/classes/custom-date';
import {TreeNodeItem, TreeNodeItems} from 'lookin-sdk';
import {TreeNode} from 'primeng/api';
import {environment} from '@/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  static weekdayNames = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
  static weekdayNamesShort = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

  static random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  static validURL(str: string) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-zа-я\\d%_.,~+\\s\\(\\)]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  static validBlob(str: string) {
    const pattern = new RegExp('^blob', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public static ObjectKeys = Object.keys;

  static randomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  async wait(condFunc: () => boolean) {
    return new Promise((resolve: any) => {
      if (condFunc()) {
        resolve();
      } else {
        setTimeout(async () => {
          await this.wait(condFunc);
          resolve();
        }, 300);
      }
    });
  }

  // @deprecated
  static strToDate(str: string) {
    if (str && str !== '') {
      const t = str.split('.');
      if (t.length === 3
        && t[0] && t[1] && t[2]
        && parseInt(t[0], 10) !== 0 && parseInt(t[1], 10) !== 0 && parseInt(t[2], 10) !== 0
      ) {
        return new Date(parseInt(t[2], 10), parseInt(t[1], 10) - 1, parseInt(t[0], 10));
      }
    }
    return null;
  }

  // @deprecated
  static dateIsoToFormat(date: string | Date, newFormat = 'dd.MM.yyyy') {
    if (typeof date === 'string') {
      date = parseISO(date);
    }

    return format(date, newFormat, {locale: ru});
  }

  // @deprecated
  static dateToIso(date: Date) {
    return date ? formatISO(date) : null;
  }

  static firstToUpperCase(str: string) {
    return str ? (str.charAt(0).toUpperCase() + str.slice(1)) : null;
  }

  static fileExt(type: (FileGroupTypes)[] = ['video', 'image', 'doc']): string[] {

    const map = {
      video: [
        'mp4', 'avi', 'wmv', 'mov', '3gp', 'flv', 'mpeg', 'webm'
      ],
      image: [
        'jpeg', 'jpg', 'gif', 'png'
      ],
      doc: [
        'pdf', 'docx', 'doc', 'rtf', 'xls', 'xlsx'
      ]
    };

    let res: string[] = [];

    type.forEach((t) => {
      res = [...res, ...map[t]];
    });

    return res;
  }

  static formatNumber(x: number) {
    return x.toString().replace(/\s/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  static removeHttp(url: string) {
    return url.replace(/^https?:\/\//, '');
  }

  static getNativeFileExt(file: any) {
    return file.name.split('.').pop().toLowerCase();
  }

  static getFormattedPhone(phone: string, code: string = '+7'): string | null {
    if (phone) {
      const regex = /^(\+\d|8)(\d{3})(\d{3})(\d{2})(\d{2})$/;
      const m = regex.exec(phone);
      if (m !== null && m.length === 6) {
        return `${code} ${m[2]} ${m[3]} ${m[4]} ${m[5]}`;
      } else {
        return phone;
      }
    }

    return null;
  }

  // @todo SAKURA-114 все методы связанные с работой даты надо вынести в отдельный класс, который отнаследовать от стандартной даты, и написать туда все необходимые мтоды по работе с ними

  static dateIsoToMonth(created: string) {

  }

  static dateIsoToYear(created: string) {

  }

  // Кастует определенный тип на переменную (полезно если обращение к переменной происходит в шаблоне)
  static asCustomDate(date: any): CustomDate {
    return date;
  }

  static convertDateToCustomDate(date: Date | string): CustomDate {
    return new CustomDate(date);
  }

  static getFormattedPrice(price: any) {
    try {
      return price ? new Intl.NumberFormat('ru-RU').format(price) : 0;
    } catch (e) {
      console.error(e);
      return price;
    }
  }

  static countFiles(files: File[], typeOfFile: string[]) {
    if (typeOfFile.includes('all')) {
      return files.length;
    }
    let returnCount = 0;
    files.forEach((value, index) => {
      if (typeOfFile.includes(value.fileType())) {
        returnCount++;
      }
    });
    return returnCount;
  }

  static getFiles(files: File[], typeOfFile: string[]) {
    const returnFiles: File[] = [];
    files.forEach((value, index) => {
      if (typeOfFile.includes(value.fileType())) {
        returnFiles.push(value);
      }
    });
    return returnFiles;
  }

  static nounDeclension(numb: number, words: any []) {
    numb = Math.abs(numb) % 100;
    if (numb > 10 && numb < 20) {
      return words[2];
    }
    const num = numb % 10;
    if (num > 1 && num < 5) {
      return words[1];
    }
    if (num === 1) {
      return words[0];
    }
    return words[2];
  }

  static parseUrlForPushData() {
    const urlParams: any = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlParams.entries());

    const data: any = HelperService.parseUrlForGetParams(params);

    return typeof data.pushData !== 'undefined' ? data.pushData : null;
  }

  static parseUrlForGetParams(params: any) {
    const newObj = {};

    for (const i in params) {
      const a = i.match(/([^\[\]]+)(\[[^\[\]]+[^\]])*?/g);
      let p = params[i];
      let j = a.length;

      while (j--) {
        const q = {};
        q[a[j]] = p;
        p = q;
      }
      // merge object
      let k = Object.keys(p)[0];
      let o = newObj;

      while (k in o) {
        p = p[k];
        o = o[k];
        k = Object.keys(p)[0];
      }

      o[k] = p[k];
    }

    return newObj;
  }

  static camelToDashCase(key: string) {
    const result = key.replace(/([A-Z])/g, ' $1');
    return result.split(' ').join('-').toLowerCase();
  }

  static openLink(url: string, openOnNewTab = true) {
    window.open(url, openOnNewTab ? '_blank' : '_self');
  }

  static setFormat(type: string): string {
    if (type === 'time') {
      return 'H : mm';
    } else if (type === 'year') {
      return 'yyyy';
    } else if (type === 'yearmonth') {
      return 'MM.yyyy';
    } else if (type === 'datetime') {
      return 'dd.MM.yyyy H:mm';
    } else {
      return 'dd.MM.yyyy';
    }
  }

  static setFormatReturn(type: string): string {
    if (type === 'time') {
      return 'HH:mm';
    } else if (type === 'year') {
      return 'yyyy';
    } else if (type === 'yearmonth') {
      return 'yyyy-MM';
    } else if (type === 'datetime') {
      return 'yyyy-MM-dd HH:mm';
    } else {
      return 'yyyy-MM-dd';
    }
  }

  static isObject(item: any): boolean {
    return (typeof item === 'object' && !Array.isArray(item) && item !== null);
  }

  static isArray(item: any): boolean {
    return Array.isArray(item);
  }

  static mergeNullValuesAndEmptyArrays(
    obj1: Record<string, any>,
    obj2: Record<string, any>
  ): Record<string, any> {

    let result: Record<string, any> = {...obj1, ...obj2};

    for (let key in result) {
      // If the value is an object, we should recursively merge
      if (HelperService.isObject(obj2[key])) {
        if (HelperService.isObject(obj1[key])) {
          result[key] = HelperService.mergeNullValuesAndEmptyArrays(obj1[key], obj2[key]);
        }
      } else if (HelperService.isArray(obj2[key])) {
        // If the value is an array, merge array items
        if (HelperService.isArray(obj1[key])) {
          result[key] = obj1[key].map((item, index) => {
            if (item === null || HelperService.isArray(item) && item.length === 0) {
              return obj2[key][index];
            }
            return item;
          });
        }
      } else {
        if (obj1[key] === null || !(key in obj1)) {
          result[key] = obj2[key];
        }
      }
    }
    return result;
  }

  static deepMerge(obj1, obj2) {
    let output = {...obj1};

    if (HelperService.isObject(obj1) && HelperService.isObject(obj2)) {
      Object.keys(obj2).forEach(key => {
        if (HelperService.isObject(obj2[key])) {
          if (!(key in obj1))
            Object.assign(output, {[key]: obj2[key]});
          else
            output[key] = this.deepMerge(obj1[key], obj2[key]);
        } else {
          Object.assign(output, {[key]: obj2[key]});
        }
      });
    }
    return output;
  }

  static toTreeNodeItems(items: any): TreeNodeItems<any> {
    if (!items || !items.length) {
      return new TreeNodeItems();
    }
    return {
      items: items.map(item => (<TreeNodeItem<any>>{
        key: (item?.slug || '') as string,
        label: (item?.title || '') as string,
        data: {...item}
      }))
    }
  }

  static toTree(items: TreeNodeItem<any>[]): TreeNode[] {
    if (!items || !Array.isArray(items)) {
      return [];
    }

    return items.map((item) => ({
      ...item,
      children: item.children && HelperService.toTree(item.children.items)
    }));
  }

  static convertNullToEmptyString(obj: {[key: string]: any}) {
    let newObj: {[key: string]: any} = {};

    Object.keys(obj).forEach(key => {
      if (!obj[key]) {
        newObj = {...newObj, [key]: ''}
      } else {
        newObj = {...newObj, [key]: obj[key]}
      }
    })

    return newObj;
  }

  static openWorkerInSakura(workerId: string) {
    const link = `${environment.sakuraUrl}/index.php?r=redesign#/personal-employee/${workerId}/summary`;

    window.open(link, '_blank');
  }
}
