import {NgModule} from '@angular/core';
import {RemoveHostDirective} from './remove-host.directive';
import {SuppressScrollEventDirective} from './supress-scroll.directive';
import {OnlyNumbersDirective} from './only-numbers.directive';
import {ScrollStateDirective} from './scroll-state.directive';
import {InputFocusedDirective} from '@/app/core/directives/input-focused.directive';
import {RippleDirective} from '@/app/core/directives/ripple.directive';
import {InputPatternDirective} from '@/app/core/directives/input-pattern.directive';

@NgModule({
  imports: [],
  declarations: [
    RemoveHostDirective,
    SuppressScrollEventDirective,
    OnlyNumbersDirective,
    ScrollStateDirective,
    InputFocusedDirective,
    RippleDirective,
    InputPatternDirective
  ],
  exports: [
    RemoveHostDirective,
    SuppressScrollEventDirective,
    OnlyNumbersDirective,
    ScrollStateDirective,
    InputFocusedDirective,
    RippleDirective,
    InputPatternDirective
  ]
})
export class DirectivesModule {
}
