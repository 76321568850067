<div class=''>
  <label>{{ label }}</label>
  <p-selectButton
      [formControl]="control"
      [options]="options"
      optionLabel="title"
      optionValue="id"
      [unselectable]="true"
  ></p-selectButton>
</div>

