import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit, signal,
  ViewChild,
  WritableSignal
} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TuiDestroyService} from '@taiga-ui/cdk';
import {delay, takeUntil} from 'rxjs/operators';
import {HelperService} from '@/app/services/helper.service';
import {AbstractNgModelComponent} from '@/app/core/abstracts/ng-model.component';
import {CustomDate} from '@/app/core/classes/custom-date';
import {InputFocusedDirective} from '@/app/core/directives/input-focused.directive';


/**
 * DatePicker component
 *
 * @example
 * Basic usage
 * <app-date-picker
 *  [formControl]="control"
 * ></app-date-picker>
 */
@Component({
  selector: 'app-date-picker',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    },
    TuiDestroyService
  ]
})
export class DatePickerComponent extends AbstractNgModelComponent implements OnInit {
  /**
   * Show clear button if value is selected
   */
  @Input() showClear: boolean = false;

  /**
   * Placeholder of date picker
   */
  @Input() placeholder: string = '';

  /**
   * Min date as Date or DateIso
   */
  @Input() minDate: Date = null;

  /**
   * Max date as Date or DateIso
   */
  @Input() maxDate: Date = null;

  /**
   * Show time picker
   */
  @Input() showTime: boolean = false;

  /**
   * Single or Range picker
   */
  @Input() selectionMode: 'range' | 'single' = 'single';

  control: FormControl;
  fb: FormBuilder;
  destroy$: TuiDestroyService;

  isFocused: WritableSignal<boolean> = signal(false);
  constructor(
    injector: Injector,
    fb: FormBuilder,
    destroy$: TuiDestroyService
  ) {
    super(injector);
    this.fb = fb;
    this.destroy$ = destroy$;
  }

  ngOnInit() {
    this.control = this.fb.control(null);

    this.control.valueChanges
      .pipe(delay(100), takeUntil(this.destroy$))
      .subscribe(v => {
        if (Array.isArray(v)) {
          const [from, to] = v || [null, null];
          if (from && to) {
            this.changeData([
              (new CustomDate(from)).toIso(),
              (new CustomDate(to)).toIso()
            ]);
          } else {
            this.changeData(null);
          }
        } else {
          this.changeData(v ? (new CustomDate(v)).toIso() : null);
        }
      });
  }

  changeData(data: any) {
    if (this.onChangeModel) {
      this.onChangeModel(data);
    }
  }

  writeValue(value) {
    super.writeValue(value);
    if (value) {
      if (Array.isArray(value)) {
        const [from, to] = value;
        this.control.setValue([new Date(from), new Date(to)], {emitEvent: false});
      } else {
        this.control.setValue(new Date(value), {emitEvent: false});
      }
    }
  }
}
