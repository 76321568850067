import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Notification} from 'lookin-sdk/types/entities/notification/Notification';

@Component({
  selector: 'app-notification-card',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationCardComponent {

  @Input() item: Notification;
  @Output() onMakeRead = new EventEmitter<Notification>();
  constructor() {
  }

  onClick() {
    if (this.item.isRead && this.item.link) {
      window.open(this.item.link, '_blank');
      return;
    }

    if (!this.item.isRead) {
      this.onMakeRead.emit(this.item);

      if (this.item.link) {
        window.open(this.item.link, '_blank');
      }
    }
  }
}
