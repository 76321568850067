export const environment = {
  title: 'Sakura Admin',
  service: 'admin',
  production: false,
  isElements: false,
  baseUrl: 'https://saasrc.api.admin.lookinsoft.ru',
  gqlUrl: 'https://saasrc.gateway.lookinsoft.ru',
  sakuraUrl: 'https://saasrc.hrm.lookinsoft.ru',
  adminUrl: 'https://saasrc.admin.lookinsoft.ru',
  publicUrl: '',
  downloadUrl: 'api/pwa/download?url=',
  apiUrl: '',
};
