import { environment } from '@/environments/environment';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_ROUTER_LIST } from '@/app/app-route-list';

const routes: Routes = environment.isElements ? [] : [...APP_ROUTER_LIST.toRouterList()];


const importsRoutes = environment.isElements ?  [] : [RouterModule.forRoot(routes, { useHash: environment.service==='sakura' })]

@NgModule({
  imports: importsRoutes,
  exports: [RouterModule],
})
export class AppRoutingModule {
}
