<div [class]="'avatar ' + size"
     [ngClass]="{
       'border-circle': rounded,
       'avatar-hover': hovered
     }"
     [ngStyle]="{
        'background-color': bgColor,
        'background-image': 'url('+ url +')'
     }"
>
  @if (!url) {
    <span>{{name}}</span>
  }
</div>
