import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ModalButton} from '@/app/entities/modal-button/modal-button.interface';

@Component({
  selector: 'app-toast',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {

  closeFunction: any;
  clickEvent: Event;

  constructor() {
  }

  buttons(message: any): ModalButton<ToastComponent>[] {
    return message.buttons as ModalButton<ToastComponent>[];
  }

  onClick(event: Event, button: ModalButton<ToastComponent>, closeFn: any) {
    this.closeFunction = closeFn;
    this.clickEvent = event;

    if (button.callbackOnClick) {
      button.callbackOnClick(this);
    }

    if (button.isClose) {
      closeFn(event);

      if (button.callbackAfterClose) {
        button.callbackAfterClose(this);
      }
    }
  }
}
