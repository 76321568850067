<div>
  <div class="range">
    <div>{{control.value[0]}}</div>
    <div>{{control.value[1]}}</div>
  </div>
  <p-slider
      [formControl]="control"
      [min]="minVal"
      [max]="maxVal"
      [range]="true"
  ></p-slider>
</div>
