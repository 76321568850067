import {NgModule} from '@angular/core';
import {ToastComponent} from './item';
import {CommonModule} from '@angular/common';
import {ToastModule as PrimeToastModule} from 'primeng/toast';
import {ButtonModule} from '@/app/components/app-components/button/item.module';
import {TimesIcon} from 'primeng/icons/times';
import {CheckIcon} from 'primeng/icons/check';
import {InfoCircleIcon} from 'primeng/icons/infocircle';
import {TimesCircleIcon} from 'primeng/icons/timescircle';
import {ExclamationTriangleIcon} from 'primeng/icons/exclamationtriangle';

@NgModule({
  imports: [
    CommonModule,
    PrimeToastModule,
    ButtonModule,
    TimesIcon,
    CheckIcon,
    InfoCircleIcon,
    TimesCircleIcon,
    ExclamationTriangleIcon
  ],
  exports: [
    ToastComponent
  ],
  declarations: [ToastComponent],
  providers: [],
})
export class ToastModule {
}
