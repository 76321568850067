import {ControlValueAccessor} from '@angular/forms';
import {ChangeDetectorRef, Component, EventEmitter, Injector, Input, Output, Type} from '@angular/core';

@Component({template: ''})
export class AbstractNgModelComponent<T = any> implements ControlValueAccessor {
  /**
   * Disabled control
   */
  @Input() disabled: boolean = false;
  /**
   * Readonly control
   */
  @Input() readonly: boolean = false;

  /**
   * Component label
   */
  @Input() label: string;

  @Output() changeModelEvent: EventEmitter<T> = new EventEmitter();
  @Output() touchModelEvent: EventEmitter<FocusEvent> = new EventEmitter();

  injector: Injector;

  ngFormAdded = false;

  protected _value: T;
  protected cdRef: ChangeDetectorRef;

  constructor(
    injector: Injector
  ) {
    this.injector = injector;
    this.cdRef = injector.get<ChangeDetectorRef>(ChangeDetectorRef as Type<ChangeDetectorRef>);
  }

  get value(): T {
    return this._value;
  }

  @Input() set value(value: T) {
    this._value = value;
    this.notifyValueChange();
  }

  onChangeModel: any = (value: T) => {
    this.changeModelEvent.emit(value);
  };

  onTouchModel: any = (event: FocusEvent) => {
    this.touchModelEvent.emit(event);
  };

  notifyValueChange(): void {
    if (this.ngFormAdded) {
      this.changeModelEvent.emit(this.value);
    }

    if (this.onChangeModel) {
      this.onChangeModel(this.value);
    }
  }

  writeValue(value: T): void {
    this._value = value;
    setTimeout(() => this.cdRef.detectChanges(), 0);
  }

  registerOnChange(fn: any): void {
    this.ngFormAdded = true;
    this.onChangeModel = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchModel = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
