<p-toast [baseZIndex]="100000">
  <ng-template let-message pTemplate="headless" let-closeFn="closeFn">
    @if (message.template) {
      <ng-container *ngTemplateOutlet="message.template; context: {item: message}"></ng-container>
    } @else {
      <div class="p-toast-message-content">
        <span class="p-toast-message-icon" style="font-size: 2rem;" [attr.aria-hidden]="true" [attr.data-pc-section]="'icon'">
          @switch (message.severity) {
            @case ('success') {
              <CheckIcon [attr.aria-hidden]="true" [attr.data-pc-section]="'icon'" />
            }
            @case ('info') {
              <InfoCircleIcon [attr.aria-hidden]="true" [attr.data-pc-section]="'icon'" />
            }
            @case ('error') {
              <TimesCircleIcon [attr.aria-hidden]="true" [attr.data-pc-section]="'icon'" />
            }
            @case ('warn') {
              <ExclamationTriangleIcon [attr.aria-hidden]="true" [attr.data-pc-section]="'icon'" />
            }
          }
        </span>

        <div class="p-toast-message-text">
          <div class="p-toast-summary">{{ message.summary }}</div>
          <div class="p-toast-detail">{{ message.detail }}</div>

          @if (message.buttons?.length) {
            <div class="mt-2 flex align-items-center gap-2 flex-wrap">
              @for (button of buttons(message); track button.settings.label) {
                <app-button
                    [icon]="button.settings.icon"
                    [label]="button.settings.label"
                    [severity]="button.settings.severity"
                    [size]="button.settings.size"
                    [raised]="button.settings.raised"
                    [outlined]="button.settings.outlined"
                    [text]="button.settings.text"
                    (onClick)="onClick($event, button, closeFn)"
                ></app-button>
              }
            </div>
          }
        </div>

        @if (message?.closable !== false) {
          <button
              type="button"
              class="p-toast-icon-close p-link"
              (click)="closeFn($event)"
              pRipple
          >
            <TimesIcon [styleClass]="'p-toast-icon-close-icon'" [attr.aria-hidden]="true" [attr.data-pc-section]="'closeicon'" />
          </button>
        }
      </div>
    }
  </ng-template>
</p-toast>

