import {Component, Injector, OnInit} from '@angular/core';
import {AbstarctCommonPage} from '@/app/core/abstracts/common-page';
import { APP_ROUTER_LIST } from '@/app/app-route-list';
import {TuiDestroyService} from '@taiga-ui/cdk';
import {takeUntil} from 'rxjs/operators';
import { RouterEntity } from '@/app/entities/router/router.entity';

@Component({
  selector: 'app-page-menu',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  providers: [TuiDestroyService]
})
export class PageMenuComponent extends AbstarctCommonPage implements OnInit {
  isCollapsed: boolean = false;

  mainMenuRoutes = [...APP_ROUTER_LIST.toMenuList('isVisibleInMainMenu')];
  bottomMenuRoutes = [...APP_ROUTER_LIST.toMenuList('isVisibleInBottomMenu')];

  constructor(
    injector: Injector,
    private destroy$: TuiDestroyService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.appService.menuIsCollapsed$.pipe(takeUntil(this.destroy$)).subscribe(isCollapsed => {
      this.isCollapsed = isCollapsed;
      localStorage.setItem("menuIsCollapsed", String(isCollapsed));
    })
  }

  toggleCollapseMenu() {
    this.appService.menuIsCollapsed$.next(!this.isCollapsed);
  }

  userHavePermissionForRoute(route: RouterEntity): boolean {
    if (route.data?.permissions?.length){
      return route.data?.permissions?.every((permission: string) => this.userModel.getUserPermission(permission));
    } else {
      return true
    }
  }
}
