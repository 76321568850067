import {
  FormElementDateInputType,
  FormElementDateSelectView,
  FormElementDateTypes,
  FormElementNumberTypes, FormElementRepeaterView, FormElementSegmentView,
  FormElementSize,
  FormElementTextTypes,
  FormElementType,
  FormElementValidationTypes
} from '../enums';
import 'reflect-metadata';
import {Transform, Type} from 'class-transformer';
import {Entity} from '@/app/core/entity/main.entity';
import {EntityArray} from '@/app/core/entity/entity-array';
import {Dictionary} from '@/app/entities/dictionary/dictionary.entity';
import {CustomDate} from '@/app/core/classes/custom-date';
import {TreeNodeItem, TreeNodeItems} from 'lookin-sdk';

export class FormElementVisibility extends Entity {
  field?: string = null;
  value?: string[] = null;
}

export class FormElementVisibilityArray extends EntityArray<FormElementVisibility> {

}

/*
  * @todo why there is two FormElementValidation ? @see app/admin-frontend/src/app/entities/form/form-element-validation.entity.ts
  * This is Vadim's code and he said: "The imports there do not work properly"
 */
export class FormElementValidation extends Entity {
  type: FormElementValidationTypes;
  text: string;
  pattern?: string;
  relation?: string;

  // only for Group
  relationFrom?: string;
  /*
   * done @todo why! you mean that backend each time must sent color like `var(--passwordWeakBg)`, really? I mean, there is a reason why backend devs, dont like frontend sometimes... It must be without var, but even if you need it, use getters...
   */
  color?: string = 'red';
  affectValidation?: boolean = true;

  constructor(options: Partial<FormElementValidation> = null) {
    super();

    Object.assign(this, options);
  }
}

export class FormElementServerError extends Entity {
  text: string;
  color?: string = 'red';
  hideOnChange?: boolean = true;
  inited?: boolean = false;

  constructor(options: Partial<FormElementServerError> = null) {
    super();

    Object.assign(this, options);
  }
}

export class FormConfiguration extends Entity {

}

export class FormElementConfiguration extends Entity {
  title?: string = null;
  placeholder?: string = null;
  type: FormElementType = null;
  description: string = null;
  elementSize?: FormElementSize = 'full';

  @Type(() => FormElementValidation)
  validation?: FormElementValidation[] = [];
}

export class FormElementConfigurationReplace extends Entity {
  applyIf: {field: string, value: string[]}[];
  isFullReplace: boolean = false;
  toReplace: {};
}

// toggle
export class FormElementToggle extends FormElementConfiguration {
  type: FormElementType = 'toggle';

  @Type(() => FormElementToggleConfiguration)
  configuration?: FormElementToggleConfiguration = new FormElementToggleConfiguration();
}

export class FormElementToggleConfiguration extends FormConfiguration {
  label?: string = null;
}

// textarea
export class FormElementTextarea extends FormElementConfiguration {
  type: FormElementType = 'textarea';

  @Type(() => FormElementCheckboxConfiguration)
  configuration?: FormElementTextareaConfiguration = new FormElementTextareaConfiguration(null);
}

export class FormElementTextareaConfiguration extends FormConfiguration {
  minLength?: number = null;
  maxLength?: number = null;
  clearable = true;
}

// text
export class FormElementText extends FormElementConfiguration {
  type: FormElementType = 'text';

  @Type(() => FormElementTextConfiguration)
  configuration?: FormElementTextConfiguration = new FormElementTextConfiguration();
}
export class FormElementTextConfiguration extends FormConfiguration {
  type?: FormElementTextTypes = 'text';
  mask?: string = null;
  minLength?: number = null;
  maxLength?: number = null;
  clearable = true;
  readonly = false;
  pattern?: string = null;
  relatedField: string = null;
}

// checkbox
export class FormElementCheckbox extends FormElementConfiguration {
  type: FormElementType = 'checkbox';

  @Type(() => FormElementCheckboxConfiguration)
  configuration: FormElementCheckboxConfiguration = new FormElementCheckboxConfiguration();
}

export class FormElementCheckboxConfiguration extends FormConfiguration {
  dictionaryCode?: string = null;
  minSelected?: number = null;
  maxSelected?: number = null;

  @Type(() => Dictionary)
  dictionaryList?: Dictionary[] = [];

}

// customCalendar
export class FormElementCustomCalendarRepeat extends FormElementConfiguration {
  type: FormElementType = 'calendarRepeat';

  @Type(() => FormElementCustomCalendarRepeatConfiguration)
  configuration?: FormElementCustomCalendarRepeatConfiguration = new FormElementCustomCalendarRepeatConfiguration();
}

export class FormElementCustomCalendarRepeatConfiguration extends FormConfiguration {
  clearable = true;

  @Type(() => CustomDate)
  @Transform(({value}) => value ? new CustomDate(value) : null)
  date: CustomDate;
}

// number
export class FormElementNumber extends FormElementConfiguration {
  type: FormElementType = 'number';

  @Type(() => FormElementNumberConfiguration)
  configuration?: FormElementNumberConfiguration = new FormElementNumberConfiguration(null);
}

export class FormElementNumberConfiguration extends FormConfiguration {
  type?: FormElementNumberTypes = 'number';
  mask?: string = null;
  clearable = true;
  minLength?: number = null;
  maxLength?: number = null;
  minValue?: number = null;
  maxValue?: number = null;
  between: boolean = false;
}


// file
export class FormElementFile extends FormElementConfiguration {
  type: FormElementType = 'file';
}

// radio
export class FormElementRadio extends FormElementConfiguration {
  type: FormElementType = 'radio';

  @Type(() => FormElementRadioConfiguration)
  configuration?: FormElementRadioConfiguration = new FormElementRadioConfiguration();
}
export class FormElementRadioConfiguration extends FormConfiguration {
  dictionaryCode?: string = null;

  @Type(() => Dictionary)
  dictionaryList?: Dictionary[] = [];

}

// select
export class FormElementSelect extends FormElementConfiguration {
  type: FormElementType = 'select';

  @Type(() => FormElementSelectConfiguration)
  configuration?: FormElementSelectConfiguration = new FormElementSelectConfiguration();
}

export class FormElementSelectConfiguration extends FormConfiguration {
  dictionaryCode?: string = null;
  multiple? = false;
  ownVariants? = false;
  search? = false;
  minSelected?: number = null;
  maxSelected?: number = null;
  searchPlaceholder?: string = null;
  notFoundText?: string = null;
  searchHint?: string = null;
  searchCustomRequest?: string = null;

  showMarkCheck? = false; // add to api

  dependantOn?: string[] = [];
  clearable = true;

  @Type(() => TreeNodeItems)
  dictionaryList?: TreeNodeItems<any> = null;

  @Type(() => FormElementSelectGqlConfiguration)
  gqlRequestConfig: FormElementSelectGqlConfiguration = null;
  // getListCollection?() {
  //   if (this.multiple) {
  //     return new SwitchCheckboxCollection(this.dictionaryList.map((item) => item.getSwitchItem()));
  //   } else {
  //     return new SwitchRadioCollection(this.dictionaryList.map((item) => item.getSwitchItem()));
  //   }
  // }
  requestSpecialMethod?: string = null;
}

export class FormElementSelectGqlConfiguration extends Entity {
  service: 'hrm' | 'admin';
  entity: string;
  additionalWhere: {};
  searchByField: {
    column: string;
    operator: string;
  }[];
  searchByFieldOperator: 'OR' | 'AND';
  fieldAsTitleSchema: string = null;
  fieldAsId: string;
  fields: string[];
}

// hidden
export class FormElementHidden extends FormElementConfiguration {
  type: FormElementType = 'hidden';

  @Type(() => FormElementHiddenConfiguration)
  configuration?: FormElementHiddenConfiguration = new FormElementHiddenConfiguration();
}

export class FormElementHiddenConfiguration extends FormConfiguration {

}

// segment
export class FormElementSegment extends FormElementConfiguration {
  type: FormElementType = 'segments';

  @Type(() => FormElementSegmentConfiguration)
  configuration?: FormElementSegmentConfiguration = new FormElementSegmentConfiguration();
}

export class FormElementSegmentConfiguration extends FormConfiguration {
  dictionaryCode?: string = null;
  view?: FormElementSegmentView = 'secondary';

  @Type(() => Dictionary)
  dictionaryList?: Dictionary[] = [];

}

// group
export class FormElementGroup extends FormElementConfiguration {
  type: FormElementType = 'group';

  @Type(() => FormElementGroupConfiguration)
  configuration?: FormElementGroupConfiguration = new FormElementGroupConfiguration();
}

export class FormElementRepeater extends FormElementConfiguration {
  type: FormElementType = 'repeater';

  @Type(() => FormElementRepeaterConfiguration)
  configuration?: FormElementRepeaterConfiguration = new FormElementRepeaterConfiguration();
}

export class FormElementGroupConfiguration extends FormConfiguration {
  fields: FormElement[] = [];
}

export class FormElementRepeaterConfiguration extends FormConfiguration {
  incrementTitle?: string = null;
  addButtonTitle?: string = null;
  view?: FormElementRepeaterView = 'full';

  fields: FormElement[] = [];
}

// date
export class FormElementData extends FormElementConfiguration {
  type: FormElementType = 'date';

  @Type(() => FormElementDateConfiguration)
  configuration?: FormElementDateConfiguration = new FormElementDateConfiguration();
}

export class FormElementDataRange extends FormElementConfiguration {
  type: FormElementType = 'dateRange';

  @Type(() => FormElementDateConfiguration)
  configuration?: FormElementDateConfiguration = new FormElementDateConfiguration();
}

export class FormElementDateConfiguration extends FormConfiguration {
  format?: string = 'dd.MM.yyyy';
  type?: FormElementDateTypes = 'date';
  selectView?: FormElementDateSelectView = 'picker';

  @Type(() => Date)
  minDate?: Date = null;

  @Type(() => Date)
  maxDate?: Date = null;
  clearable = true;
  inputType?: FormElementDateInputType = 'date-time-together';
  between: boolean = false;
}

// password
export class FormElementPassword extends FormElementConfiguration {
  type: FormElementType = 'password'

  @Type(() => FormElementPasswordConfiguration)
  configuration: FormElementPasswordConfiguration = new FormElementPasswordConfiguration()
}

export class FormElementPasswordConfiguration extends FormConfiguration {
}

export abstract class FormElement extends Entity {
  slug: string;

  @Type(() => Entity, {
    discriminator: {
      property: 'type',
      subTypes: [
        {value: FormElementText, name: 'text'},
        {value: FormElementHidden, name: 'hidden'},
        {value: FormElementTextarea, name: 'textarea'},
        {value: FormElementSelect, name: 'select'},
        {value: FormElementCheckbox, name: 'checkbox'},
        {value: FormElementData, name: 'date'},
        {value: FormElementDataRange, name: 'dateRange'},
        {value: FormElementGroup, name: 'group'},
        {value: FormElementNumber, name: 'number'},
        {value: FormElementRepeater, name: 'repeater'},
        {value: FormElementSegment, name: 'segments'},
        {value: FormElementToggle, name: 'toggle'},
        {value: FormElementRadio, name: 'radio'},
        {value: FormElementCustomCalendarRepeat, name: 'calendarRepeat'},
        {value: FormElementPassword, name: 'password'}
      ],
    },
    keepDiscriminatorProperty: true
  })
  elementConfiguration: FormElements = null;

  @Type(() => FormElementConfigurationReplace)
  elementConfigurationReplace?: FormElementConfigurationReplace[] = null;
  currentElementConfiguration: any = null;

  @Type(() => FormElementVisibility)
  hideIf?: FormElementVisibility[] = null;

  @Type(() => FormElementVisibility)
  showIf?: FormElementVisibility[] = null;

  isShow: boolean = true;
}

export type FormElements =
  FormElementText
  | FormElementTextarea
  | FormElementSelect
  | FormElementCheckbox
  | FormElementData
  | FormElementDataRange
  | FormElementGroup
  | FormElementNumber
  | FormElementRadio
  | FormElementRepeater
  | FormElementSegment
  | FormElementToggle;
