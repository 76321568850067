import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-start-loader',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StartLoaderComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
