import {Injectable, Injector} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {EMPTY, Observable, of, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import { environment } from '@/environments/environment';
import {Entity} from '@/app/core/entity/main.entity';
import {AppService} from '@/app/services/app.service';
import {ApiResponse} from '@/app/entities/api-response/api-response.entity';
import HttpClientUtils from '@/app/core/classes/http-client-utils';

@Injectable()
export abstract class MainModelService {

  public baseUrl = environment.baseUrl;
  public url: string | undefined;
  public data: Entity | undefined;

  protected apiUrl = environment.apiUrl;

  protected model: string | undefined;
  protected http: HttpClient;

  protected appService: AppService;

  constructor(
    injector: Injector
  ) {
    this.http = injector.get(HttpClient);
    this.appService = injector.get(AppService);
  }

  async getRequest(
    getData: any,
    callback: (answer: any) => void,
    url: string | null = null,
    loading: boolean = true,
    message: boolean | string = false,
    cancelRequest = true
  ) {
    this.serverRequest(url, 'GET', {}, getData, {}, callback, loading, message, cancelRequest);
  }

  async jsonRequest(
    getData: any,
    callback: (answer: any) => void,
    url: string | null = null,
    loading: boolean = true,
    cancelRequest = true
  ) {
    this.serverRequest(url, 'JSON', {}, getData, {}, callback, loading, cancelRequest);
  }

  async putRequest(
    postData: any,
    getData: any,
    callback: (answer: any) => void,
    url: string | null = null,
    loading: boolean = true,
    cancelRequest = true
  ) {
    this.serverRequest(url, 'PUT', postData, getData, {}, callback, loading, cancelRequest);
  }

  async deleteRequest(
    postData: any,
    getData: any,
    callback: (answer: ApiResponse) => void,
    url: string | null = null,
    loading: boolean = true,
    cancelRequest = true
  ) {
    this.serverRequest(url, 'DELETE', postData, getData, {}, callback, loading, cancelRequest);
  }

  async postRequest(
    postData: any,
    getData: any,
    callback: (answer: ApiResponse) => void,
    url: string | null = null,
    loading: boolean = true,
    cancelRequest = true
  ) {
    this.serverRequest(url, 'POST', postData, getData, {}, callback, loading, cancelRequest);
  }

  public getUrl(url: string | null = null): string {
    if (url && (url.includes('http://') || url.includes('https://'))) {
      return url;
    }
    return this.baseUrl + this.apiUrl + (this.model ? this.model : '') + (url ? '/' + url : '');
  }

  protected async serverRequest(
    url: string,
    method: string = 'GET',
    postData: any,
    getData: any = [],
    headers: any = null,
    callback: (response: any) => void,
    loading: boolean = true,
    message: boolean | string = false,
    cancelRequest = true
  ) {
    url = this.getUrl(url);

    const params = this.getParams(getData);


    const options: any = {
      params,
      headers,
      // mode: 'no-cors',
      withCredentials: false,
      observe: 'response'
    };
    // options.headers = {...options.headers, 'cancel-request': `${+cancelRequest}`};

    let request: any = of(null);

    let skipSerialize = false;
    if (Array.isArray(postData)) {
      skipSerialize = !!(arr => arr.every(i => typeof i === 'string' || typeof i === 'number'));
    }

    if (method === 'GET') {
      request = this.http.get<any>(url, options);
    } else if (method === 'JSON') {
      request = this.http.jsonp<any>(url + '?' + params.toString(), getData.callback);
    } else if (method === 'DELETE') {
      options.body = postData;
      request = this.http.delete<any>(url, options);
    } else if (method === 'POST') {
      request = this.http.post<any>(url, postData, options);
    } else if (method === 'PUT') {
      request = this.http.put<any>(url, postData, options);
    }

    request
      .pipe(
        catchError((error: any, caught): any => {
          if (error.status === 500 || error.status === 0 || error.status === 504) {

            if (error.status === 0 || error.status === 504) {
              this.appService.offline = true;
            } else {
              return this.handleDeepError(error);
            }

          } else {
            this.handleError(error, callback, message);
            return EMPTY;
          }

        })
      )
      .subscribe((res: any) => {
        if ((typeof res.body !== 'undefined') || (typeof res.result !== 'undefined')) {
          this.handleSuccess(res, callback);
        }

        if (res.body?.status === false) {
          this.handleError(res.body, callback, message);
        }

      }, error => {
        this.handleError(error, callback, message);
      });

  }

  protected async handleError(data: ApiResponse, callback: (answer: ApiResponse) => void, message: boolean | string = false) {
    callback(data);
  }

  protected async handleSuccess(data: ApiResponse, callback: (answer: ApiResponse) => void) {
    callback(data);
  }

  protected async handleDeepError(error: any) {

    console.log('handleDeepError');

    return throwError(error.message);
  }

  private abortFileTransfer() {

  }

  private getParams(array: any = null) {
    return array ? HttpClientUtils.toHttpParams(array) : [];
  }

}
