import {ChangeDetectorRef, Inject, Injectable, Injector} from '@angular/core';
import {ParentModalService} from '@/app/services/modals/parent-modal.service';
import {ModalEntity} from '@/app/entities/form-modal/form-modal.entity';
import {UniqueModalData} from '@/app/modals/components/unique/unique-modal.interfaces';
import {BlankModalComponent} from '@/app/modals/components/blank/item';
import {UniqueModalComponent} from '@/app/modals/components/unique/item';
import {UserModelService} from '@/app/services/user-model.service';
import {plainToInstance} from 'class-transformer';
import {Form} from '@/app/entities/form/form.entity';
import {HelperService} from '@/app/services/helper.service';
import {LookinSDK, Organization} from 'lookin-sdk';
import {ToastService} from '@/app/components/app-components/toast/toast.service';
import { ModalButton } from '@/app/entities/modal-button/modal-button.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  userModel: UserModelService;
  toast: ToastService;
  cdr: ChangeDetectorRef;

  reservedDomain = [
    'dev',
    'stage',
    'demo',
    'rc',
    'release',
    'release_candidate',
    'api',
    'chat',
    'staging',
    'prod',
    'production'
  ];

  createFormFields = [
    {
      "slug": "title",
      "elementConfiguration": {
        "type": "text",
        "title": "Название*",
        "configuration": {
          "type": "text"
        },
        "validation": [
          {
            "type": "required",
            "text": "Обязательное поле"
          }
        ]
      }
    },
    {
      "slug": "description",
      "elementConfiguration": {
        "type": "text",
        "title": "Описание",
        "configuration": {
          "type": "text"
        },
      }
    },
    {
      "slug": "domain",
      "elementConfiguration": {
        "type": "text",
        "title": "Доменное Имя*",
        "configuration": {
          "type": "slug",
          "relatedField": "title"
        },
        "validation": [
          {
            "type": "required",
            "text": "Обязательное поле"
          },
          {
            "type": "slug",
            "text": "Домен не валиден"
          }
        ]
      }
    },
  ];

  editFormFields = [
    {
      "slug": "title",
      "elementConfiguration": {
        "type": "text",
        "title": "Название*",
        "configuration": {
          "type": "text"
        },
        "validation": [
          {
            "type": "required",
            "text": "Обязательное поле"
          }
        ]
      }
    },
    {
      "slug": "description",
      "elementConfiguration": {
        "type": "text",
        "title": "Описание",
        "configuration": {
          "type": "text"
        },
      }
    },
    {
      "slug": "domain",
      "elementConfiguration": {
        "type": "text",
        "title": "Доменное Имя",
        "configuration": {
          "type": "text",
          "readonly": true
        },
      }
    },
  ];

  constructor(
    private injector: Injector,
    @Inject(ParentModalService) private readonly modalService: ParentModalService,
  ) {
    this.userModel = this.injector.get(UserModelService);
    this.toast = this.injector.get(ToastService);
  }

  open(data: ModalEntity<any>, callback?: (data: any) => void) {
    this.modalService
      .open(null, data)
      .subscribe(value => {
        if (callback) {
          callback(value);
        }
      });
  }

  openUnique(data: UniqueModalData, callback?: (data: any) => void) {
    this.open({dynamicComponent: UniqueModalComponent, data},(value) => {
      callback(value);
    })
  }

  openOrganizationModal(edit: boolean = false): void {
    const formFields = edit ? this.editFormFields : this.createFormFields;
    const form = {
      fields: plainToInstance(Form, { fields: formFields }).fields,
    };

    const buttons: ModalButton<UniqueModalComponent>[] = [
      {
        settings: {
          label: 'Отменить',
          severity: 'primary',
          text: true
        },
        isClose: true
      },
      {
        settings: {
          label: 'Сохранить',
          severity: 'primary',
        },
        isClose: false,
        callbackOnClick: edit ? this.onEditOrganization.bind(this) : this.onCreateOrganization.bind(this)
      },
    ];

    const modalData: UniqueModalData = {
      title: edit ? 'Редактировать' : 'Создать' + ' организацию',
      buttons,
      form: { ...form, value: edit ? this.userModel.user.selectedOrganization : {} }
    };

    this.openUnique(modalData, (value: UniqueModalData) => {
      console.log({ value });
    });
  }

  onCreateOrganization(modal: UniqueModalComponent): void {
    modal.makeAllFormTouched = true;
    modal.loading = true;
    setTimeout(() => {
      modal.makeAllFormTouched = false;
      if (modal.formEl.formGroup.invalid) {
        modal.loading = false;
        modal.cdr.markForCheck();
        return;
      }

      const value = HelperService.convertNullToEmptyString(modal.control.value);
      if (this.reservedDomain.includes(value.domain)) {
        modal.setErrors({domain: [{color: 'red', hideOnChange: true, inited: true, text: 'Это доменное имя уже занято'}]})
        modal.loading = false;
        modal.cdr.markForCheck();
        return;
      }

      LookinSDK.organizationModel.create(value.title, value.description, value.domain).then(res => {
        modal.loading = false;
        if (res.status) {
          modal.context.$implicit.complete();
          this.userModel.changeOrganization(res.data)
        } else {
          this.toast.add({summary: 'Ошибка', detail: res.message, severity: 'error'});
          if (res.formErrors) {
            modal.setErrors(res.formErrors);
          }
          modal.loading = false;
          modal.cdr.markForCheck();
        }
      });
    });
  }

  onEditOrganization(modal: UniqueModalComponent): void {
    modal.makeAllFormTouched = true;
    modal.loading = true;

    setTimeout(() => {
      if (modal.formEl.formGroup.invalid) {
        modal.loading = false;
        return;
      }

      const value = HelperService.convertNullToEmptyString(modal.control.value);
      LookinSDK.organizationModel.update(this.userModel.user.selectedOrganization.id, value.title, value.description, value.domain).then(res => {
        modal.loading = false;
        modal.context.$implicit.complete();
        
        if (res.status) {
          this.userModel.makeLogin()
          this.toast.add({summary: `Изменения сохранены. Организация ${value.title} успешно обновлена`, severity: 'success'});
        } else  {
          this.toast.add({summary: res.message, severity: 'error'});
          if (modal.formEl?.formGroup && res.formErrors) {
            modal.formEl.setServerErrors(res.formErrors);
          }
        }
      })
    });
  }
}
