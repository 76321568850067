/* eslint-disable @typescript-eslint/naming-convention */
import 'reflect-metadata';
import {Type} from 'class-transformer';
import {Entity} from '@/app/core/entity/main.entity';
import {FormElementText} from '@/app/entities/form/form-element-text.entity';
import {FormElementTextarea} from '@/app/entities/form/form-element-textarea.entity';
import {FormElementSelect} from '@/app/entities/form/form-element-select.entity';
import {FormElementCheckbox} from '@/app/entities/form/form-element-checkbox.entity';
import {FormElementData, FormElementDataRange} from '@/app/entities/form/form-element-date.entity';
import { FormElementGroup, FormElementRepeater } from '@/app/entities/form/form-element-group.entity';
import {FormElementNumber} from '@/app/entities/form/form-element-number.entity';
import {FormElementSegment} from '@/app/entities/form/form-element-segment.entity';
import {FormElementToggle} from '@/app/entities/form/form-element-toggle.entity';
import {FormElementRadio} from '@/app/entities/form/form-element-radio.entity';
import {FormElementCustomCalendarRepeat} from '@/app/entities/form/form-element-custom-calendar-repeat.entity';
import { FormElementHidden } from '@/app/entities/form/form-element-hidden.entity';
import {FormElement} from '@/app/entities/form/form-element.entity';

export class Form extends Entity {
  id?: number; // non exist form
  fieldId?: number; // exist form
  value?: any = null;

  title?: string;
  showModalTitle? = true;
  required? = false;

  @Type(() => FormElement)
  fields: FormElement[] = [];

  getId?() {
    return this.isExist() ? this.fieldId : this.id;
  }

  isExist?() {
    return typeof this.fieldId !== 'undefined' && !!this.fieldId;
  }
}

// export type FormElements =
//   FormElementText
//   | FormElementTextarea
//   | FormElementSelect
//   | FormElementCheckbox
//   | FormElementData
//   | FormElementDataRange
//   | FormElementGroup
//   | FormElementNumber
//   | FormElementRadio
//   | FormElementRepeater
//   | FormElementSegment
//   | FormElementToggle;
