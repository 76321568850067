import {NgModule} from '@angular/core';
import {RangeComponents} from './item';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {SliderModule} from 'primeng/slider';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SliderModule
  ],
  exports: [
    RangeComponents
  ],
  declarations: [RangeComponents],
  providers: [],
})
export class RangeModule {
}
