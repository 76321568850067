import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AppService} from '@/app/services/app.service';
import {UserModelService} from '@/app/services/user-model.service';
import { environment } from '@/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private appService: AppService,
    private userModelService: UserModelService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.indexOf(environment.baseUrl) >= 0) {
      if (this.userModelService.token) {
        if (environment.service === 'admin') {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${this.userModelService.token}`
            } as any
          });
        } else {
          request = request.clone({
            setHeaders: {
              AppToken: `${this.userModelService.token}`
            } as any
          });
        }
      }
    }

    return next.handle(request);
  }

}
