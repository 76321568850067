import { AfterContentInit, AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[onlyNumbers]'
})
export class OnlyNumbersDirective implements AfterContentInit{

  @Input() onlyNumbers: boolean;
  @Input() numberType: 'number' | null = null;
  prevValue = '';
  constructor(private el: ElementRef<HTMLInputElement>) {
  }
  ngAfterContentInit() {
    setTimeout(() => {
      this.prevValue = this.el.nativeElement.value ?? '0';
    }, 10);
  }

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    if (this.numberType) {
      return;
    }
    const e = <KeyboardEvent>event;
    if (this.onlyNumbers) {
      if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+C
        (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+V
        (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
        // Allow: Ctrl+X
        (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
        // Allow: home, end, left, right
        (e.keyCode >= 35 && e.keyCode <= 39)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
        e.preventDefault();
      }
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    if (!this.numberType) {
      return;
    }
    const val = (event.target as HTMLInputElement).value;
    if (val.search(/^(-)?([0-9]{1,})?(\.)?([0-9]{1,})?$/) === -1) {
      this.el.nativeElement.value = this.prevValue;
    } else {
      this.prevValue = val;
    }
  }
}
