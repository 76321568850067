import {NgModule} from '@angular/core';
import {NotificationCardComponent} from './item';
import {CommonModule} from '@angular/common';
import {AvatarModule} from '@/app/components/app-components/avatar/item.module';
import {DirectivesModule} from '@/app/core/directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    AvatarModule,
    DirectivesModule
  ],
  exports: [
    NotificationCardComponent
  ],
  declarations: [NotificationCardComponent],
  providers: [],
})
export class NotificationCardModule {
}
