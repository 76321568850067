import {
  Component,
  EventEmitter, Input,
  Output,
} from '@angular/core';
import { AbstractNgModelComponent } from '@/app/core/abstracts/ng-model.component';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {TriggerQueryAction, TriggerQueryEntity } from 'lookin-sdk';

@Component({
 template: '',
})
export abstract class BaseFormControlComponent extends AbstractNgModelComponent {
  /**
   * Parent loader state
   */
  @Input() loader: boolean = false;
  /**
   * Indicates whether all forms have been set as touched.
   */
  @Input() makeAllFormTouched: boolean = false;
  /**
   * Entity to request.
   */
  @Input() queryEntity: TriggerQueryEntity;

  @Input() queryAction: TriggerQueryAction;

  @Input() typeField: 'entity' | 'action';

  @Input() fieldType: any;

  @Output() additionalFormIsInvalid = new EventEmitter<boolean>();


  objectKeys = Object.keys;

  isFormControl(control: FormControl) {
    return control instanceof FormControl;
  }

  isFormGroup(group: FormGroup) {
    return group instanceof FormGroup;
  }

  isFormArray(array: FormArray) {
    return array instanceof FormArray;
  }
}
