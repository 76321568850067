import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input, OnChanges, OnInit,
  Output, SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ActionPanelButton} from '@/app/components/app-components/action-panel/action-panel-button.interface';
import {WorkerAccessToOrganization} from 'lookin-sdk';
import {TuiScrollbarComponent} from '@taiga-ui/core';

@Component({
  selector: 'app-scrollable-accordion',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScrollableAccordionComponent implements OnInit, AfterViewInit, OnChanges{
  @ViewChild(TuiScrollbarComponent, {read: ElementRef})
  private readonly scrollBar?: ElementRef<HTMLElement>;

  @ViewChild('accordionHeader') accordionHeader: ElementRef;
  @Input() maxHeight: number = 400;
  @Input() indentToRight: number = 0;
  @Input() hideBorder = false;
  @Input() openOnStart = false;
  @Output() openChange = new EventEmitter<boolean>();
  @Output() onScrollEnd = new EventEmitter<void>();
  isOpen = false;

  scrollMaxHeight: number = 0;
  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.isOpen = this.openOnStart;
  }

  ngOnChanges(changes: SimpleChanges) {
    const { maxHeight } = changes;

    if (!maxHeight.firstChange && maxHeight.currentValue !== maxHeight.previousValue) {
      this.setMaxHeightToScroll();
    }
  }

  ngAfterViewInit() {
    this.setMaxHeightToScroll();
  }

  setMaxHeightToScroll() {
    const hEl = this.accordionHeader?.nativeElement as HTMLElement;
    if (hEl) {
      this.scrollMaxHeight = this.maxHeight - hEl.offsetHeight;
      this.cdr.markForCheck();
    }
  }


  toggle(state: boolean) {
    this.isOpen = state;
    this.openChange.emit(state);
  }

  onScroll() {
    if (this.scrollBar) {
      const native = this.scrollBar.nativeElement;

      if (native.scrollHeight - 40 < native.scrollTop + native.offsetHeight) {
        this.onScrollEnd.emit();
      }
    }
  }
}
