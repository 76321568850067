import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Worker} from 'lookin-sdk/types/entities/worker/Worker';

@Component({
  selector: 'app-worker-card',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkerCardComponent implements OnInit {
  @Input() worker: Worker;
  @Input() lineLimitItem: number = 1;
  @Input() lineLimitName: number = 2;
  @Input() size: 'large' | 'medium' | 'small' = 'small';

  @Output() onWorkerClick = new EventEmitter<Worker>();
  @Output() onPositionClick = new EventEmitter<Worker>();
  @Output() onDepartmentClick = new EventEmitter<Worker>();

  isWorkerClick = false;
  isPositionClick = false;
  isDepartmentClick = false;

  constructor() {
  }

  ngOnInit() {
    this.isWorkerClick = this.onWorkerClick.observers.length > 0;
    this.isPositionClick = this.onPositionClick.observers.length > 0;
    this.isDepartmentClick = this.onDepartmentClick.observers.length > 0;
  }
}
