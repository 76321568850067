import {plainToInstance} from 'class-transformer';

/**
 * @ignore
 * @internal
 * */
export class EntityArray<T> extends Array<T> {
  entityType?: new() => T;

  pushObject?(item: Partial<T>, replaceById = false) {
    this.push(plainToInstance(this.entityType, item));
  }

  getByField?(field: string, value: string | number): T {
    return this.find((item) => (typeof (item as never)[field] !== 'undefined' && (item as never)[field] !== null) && ((item as never)[field] as unknown).toString() === value.toString());
  }

  getAllByField?(field: string, value: string | number): T[] {
    return this.filter((item) => (typeof (item as never)[field] !== 'undefined' && (item as never)[field] !== null) && ((item as never)[field] as unknown).toString() === value.toString());
  }

  getById?(id: number | string): T {
    return this.getByField('id', id);
  }

  replaceItemByField?(value: number | string, field = 'id', replaceItem: T) {
    const index = this.findIndex((item) => (((item as never)[field] as unknown).toString() === value.toString()));

    if (index > -1) {
      this[index] = replaceItem;
    }
  }

  removeByField?(field: string, value: string | number) {
    const index = this.findIndex((item: any) => item[field].toString() === value.toString());
    if (index > -1) {
      this.splice(index, 1);
    }
  }

  removeById?(id: string | number) {
    this.removeByField('id', id);
  }

  replaceItemById?(id: number, replaceItem: T) {
    return this.replaceItemByField(id, 'id', replaceItem);
  }

  getFields?(fieldKey: string) {
    return this.map((item) => (item as never)[fieldKey]);
  }

  getIds?() {
    return this.getFields('id');
  }

}
