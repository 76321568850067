import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {JsonPipe, NgForOf, NgIf} from '@angular/common';
import {InputMaskModule} from 'primeng/inputmask';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {TuiErrorModule} from '@taiga-ui/core';
import {ButtonModule} from 'primeng/button';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SegmentModule} from '@/app/components/form-components/segment/item.module';
import {CheckboxListModule} from '@/app/components/form-components/checkbox-list/item.module';
import {DatePickerModule} from '@/app/components/form-components/date-picker/item.module';
import {RangeModule} from '@/app/components/form-components/range/item.module';
import {InputModule} from '@/app/components/form-components/input/item.module';
import {SelectModule} from '@/app/components/form-components/select/item.module';
import {FormElementComponent} from '@/app/components/form-components/form-constructor/form-element/item';
import {FormSelectComponent} from '@/app/components/form-components/form-constructor/form-select/item';
import {FormGroupComponent} from '@/app/components/form-components/form-constructor/form-group/item';
import {FormArrayComponent} from '@/app/components/form-components/form-constructor/form-array/item';
import {FormControlComponent} from '@/app/components/form-components/form-constructor/form-control/item';

@NgModule({
  imports: [
    ReactiveFormsModule,
    NgIf,
    CheckboxListModule,
    DatePickerModule,
    InputMaskModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    RangeModule,
    TuiErrorModule,
    JsonPipe,
    NgForOf,
    ButtonModule,
    InputModule,
    SegmentModule,
    SelectButtonModule,
    SelectModule,
  ],
  exports: [
    FormElementComponent,
    FormSelectComponent
  ],
  declarations: [
    FormElementComponent,
    FormGroupComponent,
    FormArrayComponent,
    FormControlComponent,
    FormSelectComponent
  ]
})
export class FormModule {
}
