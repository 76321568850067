import {
  ChangeDetectionStrategy,
  Component, EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit, Output,
  SimpleChanges, TemplateRef,
} from '@angular/core';
import { FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractNgModelComponent } from '@/app/core/abstracts/ng-model.component';
import { TuiDestroyService } from '@taiga-ui/cdk';
import {debounceTime, delay, takeUntil} from 'rxjs/operators';
import { DictionaryListService } from '@/app/services/dictionary-list.service';
import {
  TriggerQueryAction,
  TriggerQueryEntity,
} from 'lookin-sdk';
import {HelperService} from '@/app/services/helper.service';
import {TreeNode} from 'primeng/api';

@Component({
   selector: 'app-form-select',
   templateUrl: 'item.html',
   styleUrls: ['item.scss'],
   changeDetection: ChangeDetectionStrategy.OnPush,
   providers: [
     {
       provide: NG_VALUE_ACCESSOR,
       useExisting: forwardRef(() => FormSelectComponent),
       multi: true,
     },
     TuiDestroyService,
   ],
 })
export class FormSelectComponent extends AbstractNgModelComponent implements OnInit, OnChanges {
  /**
   * Parent loader state
   */
  @Input() loader: boolean = false;
  @Input() elementConfig: any;
  /**
   * Header template ref
   */
  @Input() selectHeader: TemplateRef<any> = null;
  /**
   * Option template ref
   */
  @Input() selectOption: TemplateRef<any> = null;

  @Input() parentSlug: string;

  @Input() queryEntity: TriggerQueryEntity;

  @Input() queryAction: TriggerQueryAction;

  @Input() typeField: 'entity' | 'action';

  @Input() fieldType: any;

  @Output() change: EventEmitter<void> = new EventEmitter<void>();

  select: FormControl;
  fb: FormBuilder;
  dictionaryListService: DictionaryListService;
  destroy$: TuiDestroyService;

  paginator: { currentPage: number, hasMorePages: boolean, search: string | null, total: number };

  isRequestToEntity: boolean = false;
  isSearchByDefault = false;
  isGQL = false;
  isFirstLoad = true;
  lastSearch: string = '';
  currentSearch: string = '';
  loading = false;
  treeNode: TreeNode[] = [];

  constructor(
    injector: Injector,
    fb: FormBuilder,
    destroy$: TuiDestroyService,
    dictionaryListService: DictionaryListService
  ) {
    super(injector);
    this.fb = fb;
    this.destroy$ = destroy$;
    this.dictionaryListService = dictionaryListService;
  }

  ngOnInit() {
    this.select = this.fb.control(null);

    this.buildOneCollection();

    this.select.valueChanges
        .pipe(debounceTime(100), takeUntil(this.destroy$))
        .subscribe(v => {
          if (this.onChangeModel) {
            let value = null;
            if (Array.isArray(v)) {
              value = v.map((item) => item.key);
            } else if (v) {
              value = v.key;
            }
            this.onChangeModel(value);
            this.onTouchModel();
          }
        });
  }

  ngOnChanges(changes: SimpleChanges) {
    const { elementConfig } = changes;
    if (elementConfig && !elementConfig.firstChange) {
      const conditionList = JSON.stringify(elementConfig.currentValue?.configuration?.dictionaryList) !== JSON.stringify(elementConfig.previousValue?.configuration?.dictionaryList);
      const conditionGql = JSON.stringify(elementConfig.currentValue?.configuration?.gqlRequestConfig) !== JSON.stringify(elementConfig.previousValue?.configuration?.gqlRequestConfig);
      if (conditionGql || conditionList) {
        this.treeNode = [];
        this.paginator = null;
        this.isFirstLoad = true;
        this.buildOneCollection();
        this.cdRef.markForCheck();
      }
    }
  }

  writeValue(value: any) {
    super.writeValue(value);
    this.select.setValue(value, { emitEvent: false });
    const cfg = this.elementConfig.configuration;
    if (cfg.gqlRequestConfig) {
      this.isGQL = true;
      this.cdRef.markForCheck();
    }
  }

  buildOneCollection() {
    const cfg = this.elementConfig.configuration;

    if (cfg.dictionaryList && cfg.dictionaryList?.items?.length) {
      this.treeNode = HelperService.toTree(cfg.dictionaryList?.items);
      this.isSearchByDefault = true;
      this.isRequestToEntity = false;
      this.cdRef.markForCheck();
    } else if (cfg.gqlRequestConfig) {
      this.isRequestToEntity = false;
      this.loadTotalGqlList(() => {
        this.loadGqlDictionaryList()
      });
      this.cdRef.markForCheck();
    } else if (cfg.requestSpecialMethod) {
      if (cfg.requestSpecialMethod === 'RulesTreeNodeRequest') {
        this.isRequestToEntity = true;
        this.isSearchByDefault = false;
        this.cdRef.markForCheck();
      }
    } else {
      this.isSearchByDefault = false;
      this.isRequestToEntity = false;
      this.cdRef.markForCheck();
    }
  }

  loadTotalGqlList(callback: () => void) {
    this.loading = true;
    this.cdRef.markForCheck();
    this.dictionaryListService.getDictionaryListByGql(
      this.elementConfig.configuration.gqlRequestConfig,
      1,
      null,
      true,
      1,
      (list, pagination) => {
        this.paginator = {...pagination}
        if (callback) {
          callback()
        }
        this.loading = false;
        this.cdRef.markForCheck();
      });
  }

  loadGqlDictionaryList(page: number = 1, search: string = null) {
    this.loading = true;
    this.cdRef.markForCheck();
    this.dictionaryListService.getDictionaryListByGql(
      this.elementConfig.configuration.gqlRequestConfig,
      page,
      search,
      false,
      this.paginator.total ?? 1000,
      (list, pagination) => {

        if (this.isFirstLoad) {
          this.isGQL = pagination.hasMorePages;
        }

        this.isFirstLoad = false;
        const lastTree = [...this.treeNode];
        this.isSearchByDefault = search ? false : this.lastSearch ? false : !pagination.hasMorePages;
        if (pagination?.hasMorePages) {
          list.push({
            key: 'morePageLoad',
            selectable: false,
            type: 'loadMore',
            label: 'Загрузить еще'
          })
        }

        if (this.paginator?.hasMorePages) {
          lastTree.splice(-1, 1);
        }

        this.paginator = pagination;
        this.treeNode = search ? [...list] : this.lastSearch ? [...list] : [...lastTree, ...list];
        this.lastSearch = search;
        this.loading = false;
        this.cdRef.markForCheck();
      });
  }

  onLoadMore() {
    this.loadGqlDictionaryList(this.paginator.currentPage + 1, this.currentSearch);
  }

  onSearch(search: string) {
    this.currentSearch = search;
    this.loadGqlDictionaryList(1, this.currentSearch);
  }
}
