import {NgModule} from '@angular/core';
import {PasswordComponent} from './item';
import {CommonModule} from '@angular/common';
import {PasswordModule as PrimePasswordModule} from 'primeng/password';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DividerModule} from 'primeng/divider';

@NgModule({
  imports: [
    CommonModule,
    PrimePasswordModule,
    FormsModule,
    ReactiveFormsModule,
    DividerModule,
  ],
  exports: [
    PasswordComponent
  ],
  declarations: [PasswordComponent],
  providers: [],
})
export class PasswordModule {
}
