import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, timeout} from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // console.log('intercept request', request);
    // console.log('intercept next', next);

    // // this.appService.requestTimeoutExpended = false;
    // return next.handle(request).pipe(
    //   timeout(120000),
    //   catchError(err => {
    //     if (err instanceof TimeoutError) {
    //       console.error('Timeout has occurred', request.url);
    //       // this.appService.requestTimeoutExpended = true;
    //     }
    //     return next.handle(request);
    //   })
    // );

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('HttpErrorInterceptor catchError', error);

        // повторная отправка запроса
        // return next.handle(request);

        this.openNoInternetErrorModal();
        return throwError(error);
      })
    );
  }

  openNoInternetErrorModal() {
    // this.appService.openNoInternetModal();
  }

}
