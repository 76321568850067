import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ButtonEntity} from '@/app/entities/form-modal/form-modal.entity';
import {TuiAppearance} from '@taiga-ui/core';
import {TUI_ARROW} from '@taiga-ui/kit';
import {LookinSDK, WorkerAccessToOrganization, WorkerAccessToOrganizationStatus} from 'lookin-sdk';
import {AbstarctCommonPage} from '@/app/core/abstracts/common-page';
import {log} from '@angular-devkit/build-angular/src/builders/ssr-dev-server';
import {Pagination} from 'lookin-sdk/types/entities/Pagination';
import {NotificationCollection} from 'lookin-sdk/types/entities/notification/NotificationCollection';
import {Notification} from 'lookin-sdk/types/entities/notification/Notification';
import {HelperService} from '@/app/services/helper.service';
import {IService} from '@/app/interfaces/service.interface';
import {Subscription, interval} from 'rxjs';
import { AppService } from '@/app/services/app.service';
import {environment} from '@/environments/environment';

@Component({
  selector: 'app-page-header',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
})
export class PageHeaderComponent extends AbstarctCommonPage implements OnInit, AfterViewInit {
  readonly arrow = TUI_ARROW;

  @ViewChild('userMenuButton') userMenuButton: ElementRef;
  @ViewChild('userMenuTop') userMenuTop: ElementRef;
  @ViewChild('userMenuBottom') userMenuBottom: ElementRef;

  @ViewChild('serviceMenuButton') serviceMenuButton: ElementRef;
  @ViewChild('serviceMenuTop') serviceMenuTop: ElementRef;
  @ViewChild('serviceMenuBottom') serviceMenuBottom: ElementRef;

  @ViewChild('tabView') tabView: ElementRef;

  @Input() title: string;
  @Input() addButton: ButtonEntity = {
    icon: 'tuiIconPlus',
    appearance: TuiAppearance.Primary,
    size: 's',
    text: 'Добавить'
  };
  @Input() dropdownButton: Omit<ButtonEntity, 'icon'> = {
    appearance: TuiAppearance.Primary,
    size: 's',
    text: 'Выбрать'
  };
  @Input() dropdownButtonContent: TemplateRef<any>;

  @Output() addButtonClick = new EventEmitter<void>();

  isOpen = false;
  isOpenNotification = false;
  isOpenService = false;

  firstLoading = true;
  loading = false;
  notificationLoading = false;
  invitation: WorkerAccessToOrganization[];
  activeOrganization: WorkerAccessToOrganization[];

  changeOrgLoading = false;

  pagination: Pagination = <Pagination>{
    currentPage: 1,
    perPage: 1000
  };
  notifications: [NotificationCollection, Notification[], Notification[]];
  
  private pollingSubscription: Subscription;

  ngOnInit() {
    this.getData();
    this.pollingSubscription = interval(60000)
      .subscribe(() => {
        if (!JSON.parse(this.appService.getStorage('isRememberToken')) && this.appService.getStorage('createToken')) {
          const createToken = new Date(this.appService.getStorage('createToken'));
          createToken.setTime(createToken.getTime() + (60 * 60 * 1000));
          const currentTime = new Date();

          if (createToken.getTime() < currentTime.getTime()) {
            this.appService.setStorage('createToken', null);
            this.userModel.logout()
          }
        }
        this.getData()
    });
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.pollingSubscription) this.pollingSubscription.unsubscribe();
  }

  getData() {
    Promise.all([
      LookinSDK.workerAccessToOrganizationModel.getItems(this.userModel.user.id.toString(), WorkerAccessToOrganizationStatus.active),
      LookinSDK.workerAccessToOrganizationModel.getItems(this.userModel.user.id.toString(), WorkerAccessToOrganizationStatus.underConsideration),
      LookinSDK.notificationModel.q().fields(...LookinSDK.notificationModel.baseFields()).get(this.pagination.currentPage, this.pagination.perPage)
    ]).then(([active, invitation, notification]) => {
      if (invitation.status) {
        this.invitation = invitation.data;
      }

      if (active.status) {
        this.activeOrganization = active.data;
      }

      this.firstLoading = false;
      this.loading = false;

      this.setNotifications(notification)
      
      this.cdr.markForCheck();
    })
  }

  setNotifications(notification: NotificationCollection) {
    this.notifications = [
      notification,
      notification.filter(item => !item.isRead),
      notification.filter(item => item.isRead),
    ];
  }

  async selectOrganization(item: WorkerAccessToOrganization) {
    this.changeOrgLoading  = true;
    this.cdr.markForCheck();
    await this.userModel.changeOrganization(item.organization);

    this.changeOrgLoading  = false;
    this.cdr.markForCheck();
  }

  notificationUpdate(item: Notification) {
    const fIdx = this.notifications[1].findIndex(n => n.id === item.id);
    if (fIdx > -1) {
      this.notifications[1].splice(fIdx, 1);
    }
    item.markRead().then(res => {
      this.notifications[2].push(item);
    })
  }

  readAll() {
    this.notificationLoading = true;
    this.notifications[0].markReadAll().then(() => {
      LookinSDK.notificationModel
        .q()
        .fields(...LookinSDK.notificationModel.baseFields()).get(this.pagination.currentPage, this.pagination.perPage)
        .then(notification => {
          this.notifications = [
            notification,
            notification.filter(item => !item.isRead),
            notification.filter(item => item.isRead),
          ];
          this.notificationLoading = false;
          this.cdr.markForCheck();
        })
    })
  }

  rejectInvitation(item: WorkerAccessToOrganization) {
    LookinSDK.workerAccessToOrganizationModel.update(item.id, WorkerAccessToOrganizationStatus.rejected).then(res => {
      window.location.reload();
    })
  }

  acceptInvitation(item: WorkerAccessToOrganization) {
    LookinSDK.workerAccessToOrganizationModel.update(item.id, WorkerAccessToOrganizationStatus.active).then(res => {
      window.location.reload();
    })
  }

  onObscured(obscured: boolean): void {
    if (obscured) {
      this.isOpen = false;
      this.isOpenNotification = false;
      this.isOpenService = false;
    }
  }

  onActiveZone(active: boolean): void {
    this.isOpen = active && this.isOpen;
    this.isOpenNotification = active && this.isOpenNotification;
    this.isOpenService = active && this.isOpenService;
  }

  get maxUserMenuHeight(): number {
    const element = this.userMenuButton?.nativeElement as HTMLElement;
    if (element) {
      const rect = element.getBoundingClientRect();

      return window.innerHeight - rect.bottom - 70;
    }
    return 400;
  }

  get maxAccordionHeight(): number {
    const topEl = this.userMenuTop?.nativeElement as HTMLElement;
    const bottomEl = this.userMenuBottom?.nativeElement as HTMLElement;

    if (topEl && bottomEl) {
      return (this.maxUserMenuHeight - topEl.offsetHeight - bottomEl.offsetHeight) / 2;
    }

    return 150;
  }

  get maxNotificationHeight(): number {
    const nEl = this.tabView?.nativeElement as HTMLElement;

    if (nEl) {
      const navEl = nEl.querySelector('.p-tabview-nav');

      return 440 - navEl.getBoundingClientRect().height;
    }

    return 300;
  }

  get maxServiceMenuHeight(): number {
    const element = this.serviceMenuButton?.nativeElement as HTMLElement;
    if (element) {
      const rect = element.getBoundingClientRect();

      return window.innerHeight - rect.bottom - 70;
    }
    return 400;
  }

  get maxServiceAccordionHeight(): number {
    const topEl = this.serviceMenuTop?.nativeElement as HTMLElement;
    const bottomEl = this.serviceMenuBottom?.nativeElement as HTMLElement;

    if (topEl && bottomEl) {
      return (this.maxServiceMenuHeight - topEl.offsetHeight - bottomEl.offsetHeight);
    }

    return 150;
  }

  get activeOrganizations(): WorkerAccessToOrganization[] {
    return this.activeOrganization && this.userModel.user?.selectedOrganization ? this.activeOrganization.filter(item => item.organizationId.toString() !== this.userModel.user.selectedOrganization?.id) : []
  }

  openCurrentWorker() {
    HelperService.openWorkerInSakura(this.userModel.user.currentWorker().id.toString());
  }

  changeService(service: IService, disabled: boolean) {
    if (disabled) {
      return;
    }
    this.appService.getStorage('service', JSON.stringify(service))
    // this.appService.currentService$.next(service);
    if (service.url) window.location.href = this.urlWithOrganizationDomain(service.url)
  }

  urlWithOrganizationDomain(url: string) {
    const baseUrl = new URL(url);
    const organizationDomain = this.userModel.user.selectedOrganization.domain
    return baseUrl.protocol + '//' + organizationDomain + '.' + baseUrl.host + baseUrl.pathname
  }

  userHavePermissionForEditOrganization() {
    return this.userModel.getUserPermission('AdminOrganizationView') && this.userModel.getUserPermission('AdminOrganizationEdit')
  }

  appServicesWithProcessedPermissions(): IService[] {
    return environment.isElements
      ? [this.appService.services[0]]
      : this.appService.services.filter((service: IService) => this.userModel.getUserPermission(service.permission))
  }

  toggleMenu() {
    if (this.firstLoading) {
      return;
    }
    this.isOpen = !this.isOpen;
    this.cdr.markForCheck();
  }

  toggleNotification() {
    if (this.firstLoading) {
      return;
    }
    this.isOpenNotification = !this.isOpenNotification;
    this.cdr.markForCheck();
  }

  toggleService() {
    if (this.firstLoading) {
      return;
    }
    this.isOpenService = !this.isOpenService;
    this.cdr.markForCheck();
  }
}
