import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Organization} from 'lookin-sdk';
import {Worker} from 'lookin-sdk/types/entities/worker/Worker';

@Component({
  selector: 'app-organization-card',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationCardComponent {
  @Input() organization: Organization;
  @Input() worker: Worker;
  @Input() showDescription: boolean = true;
  @Input() showAccept: boolean = false;
  @Input() size: 'extra-large' | 'large' | 'medium' | 'small' = 'medium';
  @Input() lineLimitDescription: number | 'unset' = 2;
  @Input() lineLimitHeader: number | 'unset'= 1;
  @Input() canSelect: boolean = false;
  @Input() isDark: boolean = false;
  @Input() isOnMenu: boolean = false;
  @Input() noPadding: boolean = false;

  @Output() onReject = new EventEmitter<any>();
  @Output() onAccept = new EventEmitter<any>();

  constructor() {
  }
}
