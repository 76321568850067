<app-select
  [label]="elementConfig?.title"
  [formControl]="select"
  [treeItems]="treeNode"
  [selectHeader]="selectHeader"
  [selectOption]="selectOption"
  [type]="elementConfig?.configuration?.multiple ? 'multiple' : 'single'"
  [queryEntity]="queryEntity"
  [parentSlug]="parentSlug"
  [fieldType]="fieldType"
  [isRequestToEntity]="isRequestToEntity"
  [typeField]="typeField"
  [isSearchByDefault]="isSearchByDefault"
  [readonly]="loader"
  [loading]="loading"
  [isGQL]="isGQL"
  (onLoadMore)="onLoadMore()"
  (onSearch)="onSearch($event)"
></app-select>
