<div class="p-fluid">
  <label>{{label}}</label>
  <div class="checkbox">
    <p-tree
        [metaKeySelection]="false"
        [value]="treeNode"
        [selectionMode]="type"
        [(selection)]="selected"
        (selectionChange)="select()"
    >
      @if (selectOption) {
        <ng-template let-node pTemplate="default">
          <ng-container *ngTemplateOutlet="selectOption; context: {node: node}"></ng-container>
        </ng-template>
      } @else {
        <ng-template let-node pTemplate="default">
          {{node.label}}
        </ng-template>
      }
    </p-tree>
  </div>
</div>
