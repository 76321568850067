import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() style: {[klass: string]: any} | null | undefined;
  @Input() styleClass: string | undefined;
  @Input() label: string;

  // HELP: only-text
  @Input() severity: string = 'primary';
  @Input() icon: string = null;
  @Input() iconPosition: 'left' | 'right' = 'left';
  @Input() size: 'small' | 'large' = null;
  @Input() raised: boolean = false;
  @Input() outlined: boolean = false;
  @Input() text: boolean = false;
  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() expanded: boolean = false;

  @Output() onClick = new EventEmitter<Event>();

  @ViewChild('button') button;

  constructor() {
  }

  computedStyle() {
    let style = {}

    if (this.style) {
      style = {...this.style}
    }

    if (this.expanded) {
      style['width'] = '100%'
    }
    
    return style
  }
}
