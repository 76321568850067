<div [class.pb-3]="control['field']().currentElementConfiguration.type !== 'hidden'">
  @if (control['field']().currentElementConfiguration.type === 'text' || control['field']().currentElementConfiguration.type === 'number') {
    @if (['text', 'link', 'email', 'slug'].includes(control['field']().currentElementConfiguration.configuration.type)) {
      <app-input
          type="text"
          [htmlType]="control['field']().currentElementConfiguration.configuration.htmlType ?? 'text'"
          [parentSlug]="controlSlug"
          [label]="control['field']().currentElementConfiguration.title"
          [formControl]='control'
          [minLength]="control['field']().currentElementConfiguration.configuration?.minLength ?? 0"
          [maxLength]="control['field']().currentElementConfiguration.configuration?.maxLength ?? 1000"
          [placeholder]="control['field']().currentElementConfiguration.configuration.placeholder ?? ''"
          [readonly]="control['field']().currentElementConfiguration.configuration?.readonly ?? loader"
          [clearable]="control['field']().currentElementConfiguration.configuration.clearable"
          [inputStyle]="computedInputStyle()"
      ></app-input>
    }

    @if (['number', 'price'].includes(control['field']().currentElementConfiguration.configuration.type)) {
      @if (!control['field']().currentElementConfiguration.configuration.between) {
        <app-input
            type="number"
            [parentSlug]="controlSlug"
            [label]="control['field']().currentElementConfiguration.title"
            [formControl]='control'
            [minValue]="control['field']().currentElementConfiguration.configuration?.minValue"
            [maxValue]="control['field']().currentElementConfiguration.configuration?.maxValue"
            [placeholder]="control['field']().currentElementConfiguration.placeholder ?? ''"
            [readonly]="loader"
            [inputStyle]="computedInputStyle()"
        ></app-input>
      } @else {
        <app-input
            type="number-range"
            [parentSlug]="controlSlug"
            [label]="control['field']().currentElementConfiguration.title"
            [formControl]='control'
            [minValue]="control['field']().currentElementConfiguration.configuration?.minValue"
            [maxValue]="control['field']().currentElementConfiguration.configuration?.maxValue"
            [placeholder]="control['field']().currentElementConfiguration.placeholder ?? ''"
            [readonly]="loader"
            [inputStyle]="computedInputStyle()"
        ></app-input>
      }
    }

    @if (['phone'].includes(control['field']().currentElementConfiguration.configuration.type)) {
      <app-input
          type="mask"
          [htmlType]="control['field']().currentElementConfiguration.configuration.type === 'phone' ? 'tel' : 'text'"
          [slotChar]="control['field']().currentElementConfiguration.configuration.slotChar ?? '_'"
          [parentSlug]="controlSlug"
          [label]="control['field']().currentElementConfiguration.title"
          [formControl]='control'
          [mask]="control['field']().currentElementConfiguration.configuration.mask"
          [placeholder]="control['field']().currentElementConfiguration.placeholder ?? ''"
          [readonly]="control['field']().currentElementConfiguration.configuration?.readonly ?? loader"
          [inputStyle]="computedInputStyle()"
      ></app-input>
    }

    @if (['pattern'].includes(control['field']().currentElementConfiguration.configuration.type)) {
      <app-input
          type="pattern"
          [htmlType]="'text'"
          [parentSlug]="controlSlug"
          [label]="control['field']().currentElementConfiguration.title"
          [formControl]='control'
          [characterPattern]="control['field']().currentElementConfiguration.configuration.pattern"
          [placeholder]="control['field']().currentElementConfiguration.placeholder ?? ''"
          [readonly]="control['field']().currentElementConfiguration.configuration?.readonly ?? loader"
          [inputStyle]="computedInputStyle()"
      ></app-input>
    }
  }

  @if (control['field']().currentElementConfiguration.type === 'textarea') {
    <app-input
        type="textarea"
        [parentSlug]="controlSlug"
        [label]="control['field']().currentElementConfiguration.title"
        [formControl]='control'
        [placeholder]="control['field']().currentElementConfiguration.placeholder ?? ''"
        [readonly]="loader"
        [inputStyle]="computedInputStyle()"
    ></app-input>
  }

  @if (control['field']().currentElementConfiguration.type === 'toggle') {
    <app-input
        type="toggle"
        [parentSlug]="controlSlug"
        [label]="control['field']().currentElementConfiguration.title"
        [formControl]='control'
        [readonly]="loader"
        [inputStyle]="computedInputStyle()"
    ></app-input>
  }

  @if (control['field']().currentElementConfiguration.type === 'password') {
    <app-input
        type="password"
        [parentSlug]="controlSlug"
        [label]="control['field']().currentElementConfiguration.title"
        [formControl]='control'
        [placeholder]="control['field']().currentElementConfiguration.placeholder ?? ''"
        [readonly]="loader"
        [feedback]="control['field']().currentElementConfiguration.feedback ?? false"
        [inputStyle]="computedInputStyle()"
    ></app-input>
  }

  @if (control['field']().currentElementConfiguration.type === 'date') {
    <app-date-picker
        [formControl]='control'
        [label]="control['field']().currentElementConfiguration.title"
        [minDate]="control['field']().currentElementConfiguration.configuration.minDate"
        [maxDate]="control['field']().currentElementConfiguration.configuration.maxDate"
        [showClear]="control['field']().currentElementConfiguration.configuration.clearable"
        [selectionMode]="!!control['field']().currentElementConfiguration.configuration.between ? 'range' : 'single'"
        [showTime]="['datetime'].includes(control['field']().currentElementConfiguration.configuration.type)"
        [readonly]="loader"
    ></app-date-picker>
  }


  @if (control['field']().currentElementConfiguration.type === 'hidden') {
    <input
        [formControl]='control'
        type='hidden'
    >
  }

  @if (control['field']().currentElementConfiguration.type === 'segments') {
    <app-segment
        [formControl]='control'
        [options]="control['field']().currentElementConfiguration.configuration.dictionaryList"
        [readonly]="loader"
    ></app-segment>
  }

  @if (control['field']().currentElementConfiguration.type === 'select') {
    <app-form-select
        [formControl]='control'
        [elementConfig]="control['field']().currentElementConfiguration"
        [queryEntity]="queryEntity"
        [queryAction]="queryAction"
        [typeField]="typeField"
        [parentSlug]="controlSlug"
        [fieldType]="fieldType"
        [loader]="loader"
    ></app-form-select>
  }

  @if (control['field']().type === 'checkbox' || control['field']().type === 'radio') {
    <app-checkbox-list
        [formControl]='control'
        [treeItems]="control['field']().currentElementConfiguration.configuration.dictionaryList"
        [type]="control['field']().type === 'radio' ? 'single' : 'multiple'"
        [label]="control['field']().title"
        [readonly]="loader"
    ></app-checkbox-list>
  }

<!--  @if (control['field']().type === 'radio') {-->
<!--    <app-radio-list-->
<!--        [formControl]='control'-->
<!--        [labelText]="control['field']().title"-->
<!--        [collection]='collectionMap.get(controlSlug)'-->
<!--    ></app-radio-list>-->
<!--  }-->

  @if (computedMessages()) {
    <div *ngFor="let message of computedMessages()" [style.color]="getMessageColor(message.color)" class="message">{{ message.text }}</div>
  }
  
  <!-- <tui-error [error]='computedError()'></tui-error> -->

  @if (control['field']() && control['field']().currentElementConfiguration['description']) {
    <div class='mt-1 mb-1' [innerHTML]="control['field']().currentElementConfiguration['description']"></div>
  }
</div>
