import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {getFirstLatter} from '@/app/core/input-transformers/input-transform';

@Component({
  selector: 'app-avatar',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent {
  @Input() url= '';
  @Input({ transform: getFirstLatter }) name = '';
  @Input() bgColor = '#FDE68A';
  @Input() size: 'extra-large' | 'large' | 'medium' | 'small' = 'medium';
  @Input() rounded = false;
  @Input() hovered = false;

  constructor() {
  }
}
