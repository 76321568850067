import {Directive, Input, ViewContainerRef, OnDestroy, HostBinding, AfterContentInit, ElementRef} from '@angular/core';
import {Subject, fromEvent} from 'rxjs';
import {takeUntil, auditTime, startWith} from 'rxjs/operators';
import ResizeObserver from 'resize-observer-polyfill'; // https://www.npmjs.com/package/resize-observer-polyfill

const entriesMap = new WeakMap();

const ro = new ResizeObserver(entries => {
  for (const entry of entries) {

    if (entriesMap.has(entry.target)) {
      const comp = entriesMap.get(entry.target);

      comp._resizeCallback(entry);
    }
  }

});

@Directive({
  selector: '[scrollState]',
})
export class ScrollStateDirective implements OnDestroy, AfterContentInit {
  @Input() canScrollUpClass: string = 'can-scroll-up';
  @Input() canScrollDownClass: string = 'can-scroll-down';
  @Input() auditTimeMs: number = 125;

  @Input() scrollStateEnable: boolean = true;

  private readonly destroy$: Subject<void> = new Subject();
  private scrollElement: HTMLElement | any;
  private hostElement: HTMLElement | any;

  constructor(
    readonly vcRef: ViewContainerRef,
    private el: ElementRef
  ) {

    if (this.scrollStateEnable) {
      this.hostElement = vcRef.element.nativeElement;

      if (typeof this.hostElement?.getScrollElement === 'function') {
        this.hostElement.getScrollElement()?.then((e) => {
          this.scrollElement = e;

          this.startEvent();
        });
      } else {
        this.scrollElement = this.hostElement;

        this.startEvent();
      }

      // // TODO: handle host height changes as well
      // fromEvent(this.hostElement, 'scroll').pipe(
      //   auditTime(this.auditTimeMs),
      //   takeUntil(this.destroy$),
      // ).subscribe(() => {
      //   this.setClasses();
      // });
    }

    // window.requestAnimationFrame(() => this.makeLoop());
  }

  startEvent() {
    const target = this.el.nativeElement;
    entriesMap.set(target, this);
    ro.observe(target);

    this.hostElement.classList.add('scroll-listener-active');

    fromEvent(this.scrollElement, 'scroll').pipe(
      auditTime(this.auditTimeMs),
      takeUntil(this.destroy$),
    ).subscribe(() => {

      this.setClasses();
    });
  }

  public _resizeCallback(entry) {
    this.setClasses();
  }

  public ngAfterContentInit(): void {
    if (this.scrollStateEnable) {
      this.setClasses();
    }
  }

  public ngOnDestroy(): void {
    if (this.scrollStateEnable) {
      this.destroy$.next();
      this.destroy$.complete();

      const target = this.el.nativeElement;
      ro.unobserve(target);
      entriesMap.delete(target);
    }
  }

  private setClasses() {
    if (this.scrollStateEnable) {
      // TODO: check if margins/paddings have to be included in these calculations
      const canScrollUp = this.scrollElement?.scrollTop > 0;
      const canScrollDown = this.scrollElement?.scrollTop + this.hostElement.clientHeight + 5 < this.hostElement.scrollHeight;

      // TODO: make setting and removing classes platform-agnostic (use Renderer2)
      this.hostElement.classList.remove(this.canScrollDownClass);
      this.hostElement.classList.remove(this.canScrollUpClass);

      if (this.canScrollUpClass && canScrollUp) {
        this.hostElement.classList.add(this.canScrollUpClass);
      }

      if (this.canScrollDownClass && canScrollDown) {
        this.hostElement.classList.add(this.canScrollDownClass);
      }
    }
  }
}
