import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input, OnInit,
} from '@angular/core';
import {AbstractNgModelComponent} from '@/app/core/abstracts/ng-model.component';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TuiDestroyService} from '@taiga-ui/cdk';
import {takeUntil} from 'rxjs/operators';
import {Dictionary} from '@/app/entities/dictionary/dictionary.entity';


/**
 * The SegmentComponent class is a component that represents a button group.
 *
 * @example
 * <app-segment
 *   [formControl]='control'
 *   [options]="[]"
 * ></app-segment>
 *
 * @see options - for data
 */

@Component({
  selector: 'app-segment',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentComponent),
      multi: true
    },
    TuiDestroyService
  ]
})
export class SegmentComponent extends AbstractNgModelComponent implements OnInit {
  /**
   * Option for selected button
   */
  @Input() options: Dictionary[] = null;

  control = new FormControl();

  data: any;

  constructor(
    injector: Injector,
    private destroy$: TuiDestroyService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(v => {
      if (this.onChangeModel) {
        this.onChangeModel(v);
      }
    })
  }

  writeValue(value: any) {
    super.writeValue(value);
    if (value) {
      this.control.setValue(value, {emitEvent: false});
    } else {
      this.control.setValue(this.options[0].id, {emitEvent: false});
    }
  }
}
