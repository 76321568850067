import {NgModule} from '@angular/core';
import {PageMenuComponent} from './item';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ButtonModule} from '@/app/components/app-components/button/item.module';
import {IconComponentModule} from '@/app/components/app-components/icon/item.module';
import {OrganizationCardModule} from '@/app/components/app-components/organization-card/item.module';
import {DividerModule} from 'primeng/divider';
import {MenuItemComponent} from '@/app/components/custom-components/page-menu/components/menu-item/item';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    IconComponentModule,
    OrganizationCardModule,
    DividerModule
  ],
  exports: [
    PageMenuComponent,
  ],
  declarations: [PageMenuComponent, MenuItemComponent],
  providers: [],
})
export class PageMenuModule {
}
