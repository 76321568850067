import {NgModule} from '@angular/core';
import {ParentModalComponent} from './item';
import {CommonModule} from '@angular/common';
import {TuiButtonModule, TuiScrollbarModule} from '@taiga-ui/core';

@NgModule({
  imports: [
    CommonModule,
    TuiButtonModule,
    TuiScrollbarModule,
  ],
  exports: [
    ParentModalComponent
  ],
  declarations: [ParentModalComponent],
  providers: [],
})
export class ParentModalModule {
}
