import {NgModule} from '@angular/core';
import {ScrollableAccordionComponent} from './item';
import {CommonModule} from '@angular/common';
import {TuiAccordionModule} from '@taiga-ui/kit';
import {TuiScrollbarModule, TuiSvgModule} from '@taiga-ui/core';
@NgModule({
  imports: [
    CommonModule,
    TuiAccordionModule,
    TuiSvgModule,
    TuiScrollbarModule,
  ],
  exports: [
    ScrollableAccordionComponent
  ],
  declarations: [ScrollableAccordionComponent],
  providers: [],
})
export class ScrollableAccordionModule {
}
