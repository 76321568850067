import {
  ChangeDetectionStrategy,
  Component, 
  forwardRef,
  Injector,
  Input,
  ViewChild,
  OnInit
} from '@angular/core';
import {AbstractNgModelComponent} from '@/app/core/abstracts/ng-model.component';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TuiDestroyService} from '@taiga-ui/cdk';
import {takeUntil} from 'rxjs/operators';

/**
 * @example
 *
 * <app-password
 *   [formControl]="control"
 *   [feedback]="feedback"
 *   [inputStyle]="inputStyle"
 * ></app-password>
 *
 */
@Component({
  selector: 'app-password',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true
    },
    TuiDestroyService
  ]
})
export class PasswordComponent extends AbstractNgModelComponent implements OnInit {
  /**
   * Enable or disable the feedback mode for password
   * @default false
   */
  @Input() feedback: boolean = false;

  /**
   * Custom style for input element
   *
   * @example
   * {
   *   'border-color': '#f00',
   *   'border-width': '2px'
   * }
   */
  @Input() inputStyle: object;

  @ViewChild('passwordEl') passwordEl;
  control = new FormControl();

  constructor(
    injector: Injector,
    private destroy$: TuiDestroyService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(v => {
      if (this.onChangeModel) {
        this.onChangeModel(v);
      }
    })
  }

  passwordMeter() {
    if (this.passwordEl && this.passwordEl.meter) {
      const text = this.passwordEl.infoText + ' пароль';
      const passwordStrength = this.passwordEl.meter.strength;
      const passwordStrengthWithFirstUpperLeter = passwordStrength.replace(passwordStrength[0], passwordStrength[0].toUpperCase());
      const color = `var(--password${passwordStrengthWithFirstUpperLeter}Bg)`

      return { text, color }
    }
  }

  passwordElComputedInputStyle() {
    const style = {...this.inputStyle};

    if (this.passwordMeter()) {
      style['border-color'] = this.passwordMeter().color;
    }

    return style;
  }
}
