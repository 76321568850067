import {Injectable} from '@angular/core';
import { MessageService} from 'primeng/api';
import {Toast} from '@/app/components/app-components/toast/toast.intefaces';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private message: MessageService
  ) { }

  add(message: Toast) {
    this.message.add(message);
  }
}
