import {NgModule} from '@angular/core';
import {StartLoaderComponent} from './item';
import {CommonModule} from '@angular/common';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
  imports: [
    CommonModule,
    ProgressSpinnerModule
  ],
  exports: [
    StartLoaderComponent
  ],
  declarations: [StartLoaderComponent],
  providers: [],
})
export class StartLoaderModule {
}
