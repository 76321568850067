import {NgModule} from '@angular/core';
import {LoaderComponent} from './item';
import {CommonModule} from '@angular/common';
import {ProgressSpinnerModule} from 'primeng/progressspinner';

@NgModule({
  imports: [
    CommonModule,
    ProgressSpinnerModule
  ],
  exports: [
    LoaderComponent
  ],
  declarations: [LoaderComponent],
  providers: [],
})
export class LoaderModule {
}
