import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent {
  @Input() label: string;
  @Input() isActive: boolean;
  @Input() disabled: boolean;
  @Input() isHide: boolean;

  constructor() {
  }
}
