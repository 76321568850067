@for (subGroup of formArray.controls; track tracked) {
  <div
      class="mb-2 pl-3 form-array"
      [formGroup]="subGroup"
  >
    <app-form-group
        [makeAllFormTouched]="makeAllFormTouched"
        [queryEntity]="queryEntity"
        [typeField]="typeField"
        [queryAction]="queryAction"
        [formGroup]="subGroup"
        [fieldType]="fieldType"
    ></app-form-group>
    <p-button size="small"  label="Удалить группу" severity="danger" (click)="removeGroup(formArray, $index)"></p-button>
  </div>
} @empty {
  <h3>Добавьте группу</h3>
}

<p-button size="small" label="Добавить группу" (click)="addGroup(formArray)"></p-button>
