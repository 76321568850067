import {NgModule} from '@angular/core';
import {WorkerCardComponent} from './item';
import {CommonModule} from '@angular/common';
import {TuiSvgModule} from '@taiga-ui/core';
import {AvatarModule} from '@/app/components/app-components/avatar/item.module';
import {TuiLineClampModule} from '@taiga-ui/kit';
import {DirectivesModule} from '@/app/core/directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    TuiSvgModule,
    AvatarModule,
    TuiLineClampModule,
    DirectivesModule
  ],
  exports: [
    WorkerCardComponent
  ],
  declarations: [WorkerCardComponent],
  providers: [],
})
export class WorkerCardModule {
}
