import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component, EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';
import {animate, AnimationEvent, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-select-overlay-wrapper',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animation', [
      state('hidden', style({
        opacity: 0,
        scale: 0.8
      })),
      state('visible', style({
        opacity: 1,
        scale: 1
      })),
      transition('hidden <=> visible', animate('0.1s ease-in-out'))
    ])
  ]
})
export class SelectOverlayWrapperComponent implements AfterViewInit{
  @HostBinding('@animation') animationState = 'hidden';
  @HostListener('@animation.done', ['$event']) done(event: AnimationEvent) {
    if (event.toState === 'hidden') {
      this.onClose.emit();
    }
  };

  @Output() onClose = new EventEmitter<void>();

  ngAfterViewInit() {
    this.animationState = 'visible';
  }

  close() {
    this.animationState = 'hidden';
  }
}
