import {NgModule} from '@angular/core';
import {CheckboxListComponent} from './item';
import {CommonModule} from '@angular/common';
import {TuiLabelModule} from '@taiga-ui/core';
import {TuiCheckboxLabeledModule} from '@taiga-ui/kit';
import {TuiForModule} from '@taiga-ui/cdk';
import {ReactiveFormsModule} from '@angular/forms';
import {CheckboxModule} from 'primeng/checkbox';
import {TreeModule} from 'primeng/tree';

@NgModule({
  imports: [
    CommonModule,
    TuiLabelModule,
    TuiCheckboxLabeledModule,
    TuiForModule,
    ReactiveFormsModule,
    CheckboxModule,
    TreeModule
  ],
  exports: [
    CheckboxListComponent
  ],
  declarations: [CheckboxListComponent],
  providers: [],
})
export class CheckboxListModule {
}
