import {ChangeDetectionStrategy, Component, forwardRef, Injector, Input} from '@angular/core';
import {FormBuilder, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TuiDestroyService} from '@taiga-ui/cdk';
import {delay, takeUntil} from 'rxjs/operators';
import {AbstractNgModelComponent} from '@/app/core/abstracts/ng-model.component';


/**
 * Represents a range component that allows selection of a range of values.
 *
 * @example
 * <app-range
 *   [formControl]='control'
 *   [max]="1000"
 *   [min]="0"
 * ></app-range>
 */

@Component({
  selector: 'app-range',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeComponents),
      multi: true
    },
    TuiDestroyService
  ]
})
export class RangeComponents extends AbstractNgModelComponent {
  /**
   * Min value on range slider
   */
  @Input() min: number = 0;

  /**
   * Max value on range slider
   */
  @Input() max: number = 100;


  control: FormControl;
  fb: FormBuilder;
  destroy$: TuiDestroyService;
  constructor(
    injector: Injector,
    fb: FormBuilder,
    destroy$: TuiDestroyService
  ) {
    super(injector);
    this.fb = fb;
    this.destroy$ = destroy$;
  }

  ngOnInit() {
    this.control = this.fb.control(null);

    this.control.valueChanges
      .pipe(delay(100), takeUntil(this.destroy$))
      .subscribe(v => {
        if (this.onChangeModel) {
          this.onChangeModel(v);
        }
      });
  }

  writeValue(value) {
    super.writeValue(value);
    const [min, max] = value || [null, null];
    this.control.setValue([
      min || this.minVal,
      max || this.maxVal
    ], {emitEvent: false});
  }

  get minVal() {
    return this.min || 0;
  }

  get maxVal() {
    return this.max || 100
  }
}
