import {NgModule} from '@angular/core';
import {InputComponent} from './item';
import {CommonModule} from '@angular/common';
import {InputTextModule} from 'primeng/inputtext';
import {ReactiveFormsModule} from '@angular/forms';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputSwitchModule} from 'primeng/inputswitch';
import {PasswordModule} from '@/app/components/form-components/password/item.module';
import {RangeModule} from '@/app/components/form-components/range/item.module';
import {DividerModule} from 'primeng/divider';
import {DirectivesModule} from '@/app/core/directives/directives.module';

@NgModule({
  imports: [
    CommonModule,
    InputTextModule,
    ReactiveFormsModule,
    InputNumberModule,
    RangeModule,
    InputMaskModule,
    InputTextareaModule,
    InputSwitchModule,
    PasswordModule,
    DividerModule,
    DirectivesModule,
  ],
  exports: [
    InputComponent
  ],
  declarations: [InputComponent],
  providers: [],
})
export class InputModule {
}
