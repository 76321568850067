<p-password
  #passwordEl
  autocomplete="new-password"
  [formControl]="control"
  [feedback]="feedback"
  [toggleMask]="true"
  [inputStyle]="passwordElComputedInputStyle()"
  [promptLabel]="'Введите пароль'"
  [weakLabel]="'Слабый'"
  [mediumLabel]="'Средний'"
  [strongLabel]="'Надежный'"
>
  @if (feedback) {
    <ng-template pTemplate="header">
      <h5 style="margin: 0 0 14px;">Выберите пароль</h5>
    </ng-template>
    <ng-template pTemplate="footer">
      <p-divider></p-divider>
      <p class="mt-2">Пароль должен содержать:</p>
      <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5; list-style-type: disc;">
        <li>8 и более символов</li>
        <li>прописные латинские буквы</li>
        <li>строчные латинские буквы</li>
        <li>цифры</li>
      </ul>
    </ng-template>
  }
</p-password>

@if (feedback && passwordMeter()) {
  <div [style.color]="passwordMeter().color" class="meter-text">{{ passwordMeter().text }}</div>
}