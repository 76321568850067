import {NgModule} from '@angular/core';
import {PageHeaderComponent} from './item';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {
  TuiButtonModule,
  TuiDropdownModule,
  TuiHostedDropdownModule,
  TuiScrollbarModule,
  TuiSvgModule
} from '@taiga-ui/core';
import {InputModule} from '@/app/components/form-components/input/item.module';
import {InputSearchModule} from '@/app/components/form-components/input-search/item.module';
import {ButtonModule} from '@/app/components/app-components/button/item.module';
import {IconComponentModule} from '@/app/components/app-components/icon/item.module';
import {AvatarModule} from '@/app/components/app-components/avatar/item.module';
import {TuiActiveZoneModule, TuiObscuredModule} from '@taiga-ui/cdk';
import {ScrollableAccordionModule} from '@/app/components/app-components/scrollable-accordion/item.module';
import {TabViewModule} from 'primeng/tabview';
import {NotificationCardModule} from '@/app/components/app-components/notification-card/item.module';
import {OrganizationCardModule} from '@/app/components/app-components/organization-card/item.module';
import {WorkerCardModule} from '@/app/components/app-components/worker-card/item.module';
import {LoaderModule} from '@/app/components/app-components/loader/item.module';
import {DirectivesModule} from '@/app/core/directives/directives.module';
import {BadgeModule} from 'primeng/badge';

@NgModule({
  imports: [
    CommonModule,
    TuiButtonModule,
    TuiHostedDropdownModule,
    InputModule,
    InputSearchModule,
    ButtonModule,
    IconComponentModule,
    AvatarModule,
    TuiSvgModule,
    TuiDropdownModule,
    TuiActiveZoneModule,
    TuiObscuredModule,
    TuiScrollbarModule,
    ScrollableAccordionModule,
    TabViewModule,
    NotificationCardModule,
    OrganizationCardModule,
    WorkerCardModule,
    LoaderModule,
    DirectivesModule,
    NgOptimizedImage,
    BadgeModule
  ],
  exports: [
    PageHeaderComponent,
  ],
  declarations: [PageHeaderComponent],
  providers: [],
})
export class PageHeaderModule {
}
