import {NgModule} from '@angular/core';
import {SegmentComponent} from './item';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SelectButtonModule} from 'primeng/selectbutton';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectButtonModule,
    FormsModule,
  ],
  exports: [
    SegmentComponent
  ],
  declarations: [SegmentComponent],
  providers: [],
})
export class SegmentModule {
}
