@if (organization) {
  <div [class]="'flex org ' + size"
       [class.cursor-pointer]="canSelect"
       [class.is-dark]="isDark"
       [class.is-on-menu]="isOnMenu"
       [class.p-0]="noPadding"
       [class.org-hover]="canSelect"
       appRipple
       [disable]="!canSelect"
  >
    <app-avatar [size]="size" [url]="organization.logo" [bgColor]="organization.color" [name]="organization.title"></app-avatar>
    <div class="flex-1">
      <div class="org-header" [ngStyle]="{'-webkit-line-clamp': lineLimitHeader}">{{organization.title}}</div>
      @if (showDescription) {
        <div class="org-descr" [ngStyle]="{'-webkit-line-clamp': lineLimitDescription}">{{organization.description}}</div>
      }

      @if (showAccept) {
        <div class="org-actions flex align-items-center">
          <app-button [text]="true" styleClass="p-1" size="small" (onClick)="onAccept.emit(organization)">Принять</app-button>
          /
          <app-button [text]="true" styleClass="p-1" size="small" (onClick)="onReject.emit(organization)">Отклонить</app-button>
        </div>
      }

      @if (worker) {
        <div class="org-worker" [class.org-worker-only]="!showAccept && !showDescription">
          <app-worker-card [worker]="worker"></app-worker-card>
        </div>
      }
    </div>
  </div>
}
