import {NgModule} from '@angular/core';
import {IconComponent} from './item';
import {NgClass, NgIf, NgStyle} from '@angular/common';

@NgModule({
  imports: [
    NgStyle,
    NgClass,
    NgIf
  ],
  exports: [
    IconComponent
  ],
  declarations: [IconComponent],
  providers: [],
})
export class IconComponentModule {
}
