<tui-root>
  @if (!environment.isElements) {
    @if (appService.isLoading) {
      <app-start-loader></app-start-loader>
    } @else {
      @if (userModel.isLogin() && userModel.user && userModel.user.selectedOrganization) {
        @if (isAdmin && pageIsInAppContainer()) {
          <app-page-header></app-page-header>
          <div class="app" [class.app-menu-hide]="appService.menuIsCollapsed$ | async">
            @if (menuIsVisble()) {
              <app-page-menu></app-page-menu>
            }
            <div class="app-content flex-1">
              <router-outlet></router-outlet>
            </div>
          </div>
        } @else {
          <router-outlet></router-outlet>
        }
      } @else {
        <router-outlet></router-outlet>
      }
    }
  } @else {
    @if (userModel.isLogin() && userModel.user) {
      <app-page-header></app-page-header>
    }
  }
</tui-root>
<app-toast></app-toast>
