import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Injectable} from '@angular/core';
import {AppService} from '@/app/services/app.service';
import {UserModelService} from '@/app/services/user-model.service';
import {HelperService} from '@/app/services/helper.service';
import {Location} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UnAuthGuard  {
  userModelService: UserModelService;
  router: Router;
  location: Location

  constructor(
    userModelService: UserModelService,
    router: Router,
    location: Location
  ) {
    this.userModelService = userModelService;
    this.router = router;
    this.location = location;
  }

  async canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    if (this.userModelService.isLogin()) {
      await this.router.navigate(['/'], {replaceUrl: true});
      return false;
    }

    return true;
  }

}
