
import {ChangeDetectionStrategy, Component, Injector, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {debounceTime, distinctUntilChanged, takeUntil, tap} from 'rxjs/operators';
import {TuiDestroyService} from '@taiga-ui/cdk';
import {BaseFormComponent} from '@/app/components/form-components/form-constructor/base/base-form.component';
import slug from 'slug';

/**
 * This component is only used in FormArrayComponent and FormElementComponent to group FormControls
 *
 * @example
 * <app-form-group
 *   [makeAllFormTouched]="false"
 *   [formGroup]='control'
 * ></app-form-group>
 */
@Component({
  selector: 'app-form-group',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    TuiDestroyService
  ]
})
export class FormGroupComponent extends BaseFormComponent implements OnInit, OnChanges{
  @Input() formGroup: FormGroup | any;
  @Input() isFirstGroup: boolean = false;

  private _isLoaded: boolean[] = [];
  constructor(
    injector: Injector,
    private destroy$: TuiDestroyService,
  ) {
    super(injector);
  }


  /**
   * This method checks and sets the validators for each field in the form group. It also subscribes to the valueChanges
   * observable of the form group, debounce the changes, and checks the fields for validity changes.
   */
  ngOnInit() {
    this.formSubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    const { formGroup } = changes;
    if (formGroup && formGroup.firstChange) {
      this.updateFieldsAndValidators();
    } else if (this.isFirstGroup && formGroup) {
      const currentControlFields = Object.values(formGroup.currentValue.controls).map(control => ({
        ...control['field']()
      }));
      const previousControlFields = Object.values(formGroup.previousValue.controls).map(control => ({
        ...control['field']()
      }));
      if (JSON.stringify(currentControlFields) !== JSON.stringify(previousControlFields)) {
        this.updateFieldsAndValidators();
        this.formSubscribe();
      }
    }
  }

  formSubscribe() {
    this.formGroup.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged((previous, current) => {
          return JSON.stringify(previous) === JSON.stringify(current);
        }),
        takeUntil(this.destroy$)
      ).subscribe(v => {
      Object.keys(v).forEach(key => {
        this.checkFields(this.formGroup, key);
      });
    });


    const relatedControls = [];

    Object.keys(this.formGroup.controls).forEach(key => {
      const control = this.formGroup.get(key);
      if (control['field']().currentElementConfiguration.configuration && control['field']().currentElementConfiguration.configuration['relatedField'] && control['field']().currentElementConfiguration.configuration.type === 'slug') {
        relatedControls.push(control);
      }
    })

    relatedControls.forEach((control, idx) => {
      const relatedControlKey = control['field']().currentElementConfiguration.configuration['relatedField'];
      const relatedControl = this.formGroup.get(relatedControlKey);

      if(relatedControl) {
        relatedControl.valueChanges.pipe(
          tap(val => {
            if (val) {
              if (!this._isLoaded[idx]) {
                control.setValue(' ');
              }
              this._isLoaded[idx] = true;
            } else {
              this._isLoaded[idx] = false;
            }
          }),
          debounceTime(350),
          distinctUntilChanged(),
          takeUntil(this.destroy$)
        ).subscribe(val => {
          control.setValue(slug(val ?? ''));
        });
      }
    })
  }

  updateFieldsAndValidators() {
    Object.keys(this.formGroup.controls).forEach(key => {
      this.checkFields(this.formGroup, key);
      const fieldKeys = Object.keys(this.formGroup.controls);
      if (fieldKeys.length) {
        fieldKeys.forEach(key => {
          const item = this.formGroup.get(key);
          item.clearValidators();
          item.addValidators(this.getValidationArray(item, this.formGroup));
        });
      }
    });
  }
}
