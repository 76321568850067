import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[coreSuppressScrollEvent]'
})
export class SuppressScrollEventDirective implements OnInit {
  public constructor(private el: ElementRef) {
  }

  public ngOnInit(): void {
    (this.el.nativeElement as HTMLElement).ontouchmove = (e) => {
      if (this.el.nativeElement.shadowRoot.querySelector('.inner-scroll').classList.contains('scroll-y')) {

        // if (this.el.nativeElement.shadowRoot.querySelector('.inner-scroll').scrollTop > 0) {
        e.stopPropagation();
        // }

      }
    };
  }
}
