import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TuiDestroyService} from '@taiga-ui/cdk';
import {AbstractNgModelComponent} from '@/app/core/abstracts/ng-model.component';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-input-search',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSearchComponent),
      multi: true
    },
    TuiDestroyService
  ]
})
export class InputSearchComponent extends AbstractNgModelComponent implements OnInit, OnChanges {

  control = new FormControl<string>('');

  constructor(
    injector: Injector,
    private destroy$: TuiDestroyService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.control.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(v => {
      if (this.onChangeModel) {
        this.onChangeModel(v);
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    const { disabled } = changes;

    if (disabled.currentValue !== disabled.previousValue) {
      if (disabled.currentValue) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  writeValue(value: string) {
    this.control.setValue(value, {emitEvent: false});
  }
}
