<div class="p-fluid floated">
  <label class="floated-label" [class.floated-active]="isFocused() || control.value">{{label}}</label>

  <p-calendar
      dateFormat="dd.mm.yy"
      [formControl]="control"
      [readonlyInput]="true"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [showClear]="showClear"
      [selectionMode]="selectionMode"
      [showTime]="showTime"
      [firstDayOfWeek]="1"
      (onShow)="isFocused.set(true)"
      (onClose)="isFocused.set(false)"
  ></p-calendar>
</div>
