@if (formGroup) {
  <app-form-group
      [makeAllFormTouched]="makeAllFormTouched"
      [formGroup]="formGroup"
      [isFirstGroup]="true"
      [queryEntity]="queryEntity"
      [queryAction]="queryAction"
      [typeField]="typeField"
      [fieldType]="fieldType"
      [loader]="loader"
  ></app-form-group>
}
