import {AfterViewInit, Directive, ElementRef, signal, WritableSignal} from '@angular/core';
import {TuiDestroyService} from '@taiga-ui/cdk';
import {fromEvent} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[appInputFocused]',
  providers: [TuiDestroyService]
})
export class InputFocusedDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    private destroy$: TuiDestroyService
  ) { }

  inputElement: HTMLInputElement = null;
  isFocus: WritableSignal<boolean> = signal(false);

  ngAfterViewInit() {
    this.inputElement = this.el.nativeElement.querySelector('input, textarea');
    if (this.inputElement) {
      fromEvent(this.inputElement, 'focus').pipe(takeUntil(this.destroy$)).subscribe(_ => {
        this.isFocus.set(true);
      });

      fromEvent(this.inputElement, 'blur').pipe(takeUntil(this.destroy$)).subscribe(_ => {
        this.isFocus.set(false);
      });
    }
  }
}
