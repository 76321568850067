import {Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges, TemplateRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractNgModelComponent} from '@/app/core/abstracts/ng-model.component';
import {TreeNode} from 'primeng/api';
import {HelperService} from '@/app/services/helper.service';
import { TreeNodeItems } from 'lookin-sdk';

@Component({
  selector: 'app-checkbox-list',
  templateUrl: 'item.html',
  styleUrls: ['item.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxListComponent),
      multi: true
    }
  ]
})
export class CheckboxListComponent extends AbstractNgModelComponent implements OnInit, OnChanges {
  @Input() selectOption: TemplateRef<any> = null;
  @Input() type: 'single' | 'multiple' | 'checkbox' = 'single';
  @Input() treeItems: TreeNodeItems<any>;

  treeNode: TreeNode[]

  selected: any;
  isFirstLoad = true;

  ngOnInit() {
    this.treeNode = HelperService.toTree(this.treeItems.items);
  }

  ngOnChanges(changes: SimpleChanges) {
    const {treeItems} = changes
    if (treeItems && !treeItems.firstChange && JSON.stringify(treeItems.previousValue) !== JSON.stringify(treeItems.currentValue)) {
      this.treeNode = HelperService.toTree(this.treeItems.items);
    }
  }

  writeValue(value) {
    super.writeValue(value);
    if (value) {
      this.selected = this.type === 'single' ? value : [...value];
      this.nodeGetValue();
    }
  }

  nodeGetValue() {
    if (!this.isFirstLoad) {
      return;
    }

    this.isFirstLoad = false;
    const selectedKeys = Array.isArray(this.selected) ? this.selected : [this.selected];
    const selected = selectedKeys.reduce((acc, key) => {
      const item = this.treeNode.find(item => item.key === key);
      acc.push(item);
      return acc;
    }, [])

    this.selected = this.type === 'single' ? selected[0] : selected;
    setTimeout(() => {
      this.select();
    }, 100);
  }

  select() {
    if (this.onChangeModel) {
      const keys = (Array.isArray(this.selected) ? this.selected : [this.selected])
        .map(item => item.key);
      this.onChangeModel(keys);
      this.onTouchModel();
    }
  }
}
