import { environment } from '@/environments/environment';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import {
  TuiRootModule,
  TuiDialogModule,
  TuiAlertModule,
  TUI_SANITIZER,
  TuiThemeNightModule,
  TuiModeModule,
} from '@taiga-ui/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgModule, isDevMode, ApplicationRef, Injector} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpErrorInterceptor } from '@/app/core/interceptors/httpError.interceptor';
import { TokenInterceptor } from '@/app/core/interceptors/token.interceptor';
import { CancelSameRequestInterceptor } from '@/app/core/interceptors/cancelSameRequest.interceptor';
import {MODAL_PROVIDER} from '@/app/services/modals/parent-modal.service';
import {TuiSheetDialogModule} from '@taiga-ui/addon-mobile';
import {MessageService} from 'primeng/api';
import {ToastModule} from '@/app/components/app-components/toast/item.module';
import {ParentModalModule} from '@/app/modals/parent-modal/item.module';
import {TUI_DIALOG_CLOSES_ON_BACK} from '@taiga-ui/cdk';
import {of} from 'rxjs';
import {StartLoaderModule} from '@/app/components/app-components/start-loader/item.module';
import {PageHeaderModule} from '@/app/components/custom-components/page-header/item.module';
import {PageMenuModule} from '@/app/components/custom-components/page-menu/item.module';
import {createCustomElement} from '@angular/elements';

const providers = [
  {
    provide: TUI_SANITIZER,
    useClass: NgDompurifySanitizer,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  },
  MessageService,
  MODAL_PROVIDER,
  {
    provide: TUI_DIALOG_CLOSES_ON_BACK,
    useValue: of(true),
  },
];

if (environment.service==='sakura') {
  providers.push({
    provide: LocationStrategy,
    useClass: HashLocationStrategy,
  });
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    TuiRootModule,
    TuiDialogModule,
    TuiAlertModule,
    HttpClientModule,
    TuiSheetDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ToastModule,
    ParentModalModule,
    StartLoaderModule,
    PageHeaderModule,
    PageMenuModule
  ],
  providers: providers,
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    if (environment.isElements) {
      const appElement = createCustomElement(AppComponent, { injector: this.injector });
      customElements.define('app-root', appElement);
    }
  }

  ngDoBootstrap(appRef: ApplicationRef) {
    if (document.querySelector('app-root')) {
      appRef.bootstrap(AppComponent);  // Regular Angular bootstrap
    }
  }
}
