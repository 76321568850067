import 'reflect-metadata';
import {Entity} from '@/app/core/entity/main.entity';
import {AppColorList} from '@/app/entities/enums';
import {BlockElementLabel} from '@/app/entities/block-elements/block-element-container';
import {Transform} from 'class-transformer';
import {Subscription} from 'rxjs';

export class SwitchItem extends Entity {
  id: string | number;

  title?: string = null;
  titleCounter?: string = null;
  selectedTitle?: string = null;

  icon?: string = null;
  color?: AppColorList | string = null;
  selectedColor?: AppColorList | string = null;
  selectedBackground?: AppColorList | string = null;

  disabled? = false;
  required? = false;
  selected? = false;
  visible? = true;
  indeterminate? = false;
  subItems?: SwitchItem[] = [];
  // subCollection?: SwitchCheckboxCollection | SwitchRadioCollection = null;

  prefix?: SwitchItemTitlePrefix = null;

  label?: BlockElementLabel = null;

  @Transform(({value}) => {

    // for some shity reason, it converts CustomDate back to Date, probably need to do something with prototype of CustomDate
    if (value) {
      for (const [k, v] of Object.entries(value)) {
        if (v instanceof Date) {
          value[k] = v.toCustomDate();
        }
      }
    }

    return value;
  })
  data?: any = null;
  sub?: Subscription = null;

  constructor(data: Partial<SwitchItem> = null) {
    super();

    Object.assign(this, data);
  }
}

export class SwitchItemTitlePrefix {
  text: string;
  color: AppColorList;
}
