import {Component, Injector, signal, WritableSignal} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormElement, FormElementConfigurationReplace} from '@/app/entities/form/form-element.entity';
import {biggerThen, lessThen, sameAs, equal, slug} from '@/app/core/directives/validators.directive';
import {HelperService} from '@/app/services/helper.service';
import {
  BaseFormControlComponent
} from '@/app/components/form-components/form-constructor/base/base-form-control.component';


@Component({
  template: ''
})
export abstract class BaseFormComponent extends BaseFormControlComponent {

  formBuilder: FormBuilder;

  constructor(
    injector: Injector
  ) {
    super(injector);
    this.formBuilder = injector.get(FormBuilder);
  }



  /**
   * Creating FormGroup, FormArray and FormControl depending on type.
   *
   * @param {FormGroup} formGroup - The form group to add controls to.
   * @param {FormElement[]} [fields=[]] - The list of form elements specifying the controls to be added.
   *
   */
  buildFormGroup(formGroup: FormGroup, fields: FormElement[] = []) {
    fields.forEach((field) => {
      const createFormControls = field.elementConfiguration.type === 'group'
        ? this.createFormGroup(field)
        : field.elementConfiguration.type === 'repeater'
          ? this.createArray(field)
          : this.createControl(field);

      formGroup.addControl(field.slug, createFormControls, {emitEvent: false});
    });

    this.cdRef.markForCheck();
  }


  /**
   * Creates a new FormGroup.
   *
   * @param {FormElement | any} field - FormElement for new FormGroup.
   * @return {FormGroup} - The created FormGroup.
   */
  createFormGroup(field: FormElement | any) {
    const group: FormGroup = new FormGroup({});
    group['field'] = signal(field);

    this.buildFormGroup(group, field.elementConfiguration.configuration.fields);

    return group;
  }


  /**
   * Creates a new FormControl.
   *
   * @param {FormElement} field - FormElement for new FormControl.
   *
   * @return {FormControl} - The newly created FormControl.
   */
  createControl(field: FormElement) {
    const control = new FormControl();
    control['field'] = signal(field);

    return control;
  }

  /**
   * Creates a new FormArray.
   *
   * @param {FormElement | any} field - FormElement for new FormArray.
   * @return {FormGroup} - The created FormArray.
   */
  createArray(field: FormElement) {
    const array = new FormArray([]);
    array['field'] = signal(field);

    return array;
  }

  /**
   * Removing FormGroup from FormArray by index.
   *
   * @param {FormArray} array - The FormArray from which to remove the FormGroup.
   * @param {number} arrayIndex - The index of the FormGroup to be removed.
   */
  removeGroup(array: any, arrayIndex: number) {
    array.removeAt(arrayIndex);
  }


  /**
   * Adds a group to the provided FormArray.
   *
   * @param {FormArray|any} array - The FormArray to which the group will be added.
   * @return {FormGroup} - The created FormGroup that was added to the FormArray.
   */
  addGroup(array: FormArray | any) {
    let group = null;

    group = this.createFormGroup(array['field']());
    array.push(group);

    return group;
  }



  /**
   * Retrieves an array of validators based on the control's field configuration
   *
   * @param {AbstractControl} control - The control for which to retrieve validators
   * @param {FormGroup} group - The form group in which the control belongs
   * @returns {Array} - An array of validators
   */
  getValidationArray(control: AbstractControl, group: FormGroup) {
    const validation = [];
    const fieldValidation = control['field']().currentElementConfiguration?.validation;
    if (fieldValidation) {
      fieldValidation.forEach((val) => {
        if (val.type === 'required') {
          validation.push(Validators.required);
        }

        if (val.type === 'pattern') {
          validation.push(Validators.pattern(val.pattern));
        }

        if (['lessThen', 'biggerThen'].includes(val.type)) {
          validation.push(lessThen(val.relation, val.text));
          control.valueChanges.subscribe((value) => {
            group?.get(val.relation)?.markAsTouched();
            group?.get(val.relation)?.updateValueAndValidity({emitEvent: false});
          });
        }

        if (val.type === 'sameAs') {
          validation.push(sameAs(val))
        }

        if (val.type === 'equal') {
          validation.push(equal(val))
        }

        if (val.type === 'email') {
          validation.push(Validators.email);
        }

        if (val.type === 'slug') {
          validation.push(slug());
        }
      });
    }

    return validation;
  }



  /**
   * Updating isShow state and control configuration.
   *
   * @param {FormGroup} formGroup - The form group to check.
   * @param {string} key - The key of the field to update.
   */
  checkFields(formGroup: FormGroup, key: string) {
    const isShow = this.getIsShow(formGroup, key);
    const config = this.getCurrentConfig(formGroup, key);
    const field = formGroup.get(key)['field'] as WritableSignal<any>;
    field.update(field => {
      return {
        ...field,
        isShow,
        currentElementConfiguration: config
      }
    });
  }


  /**
   * Checks if a form control should be shown or hidden based on specified conditions.
   *
   * @param {FormGroup} formGroup - The form group containing the control.
   * @param {string} controlSlug - The slug or name of the control.
   * @return {boolean} Returns `true` if the control should be shown, otherwise `false`.
   */
  getIsShow(formGroup: FormGroup, controlSlug: string): boolean {
    const field = formGroup.get(controlSlug)['field']();

    const hideIf = field.hideIf;
    const showIf = field.showIf;

    const condition = (cond: any) => cond.reduce((cond: boolean[], item) => {
      const value = formGroup.get(item.field)?.value;
      cond.push(item.value.includes(value));
      return cond;
    }, []);

    if (hideIf) {
      return !condition(hideIf).some(el => el===true);
    } else if (showIf) {
      return condition(showIf).some(el => el===true);
    }

    return true;
  }


  /**
   * Retrieves the current configuration for form control.
   *
   * @param {FormGroup} formGroup - The form group containing the control.
   * @param {string} controlSlug - The slug identifying the control.
   * @returns {FormElementConfigurationReplace | FormElementConfiguration | null} - The current configuration.
   */
  getCurrentConfig(formGroup: FormGroup, controlSlug: string) {
    const field = formGroup.get(controlSlug)['field']();
    const configurationReplace = field.elementConfigurationReplace;
    let replacedCfg: FormElementConfigurationReplace | null;
    if (configurationReplace) {
      let fields = [];
      configurationReplace.forEach(item => {
        const nField = item.applyIf.find(apItem => formGroup.get(apItem.field)) ? item:null;
        if (nField) {
          fields.push(nField);
        }
      });

      if (fields && fields.length) {
        replacedCfg = fields.find(item => !!item.applyIf.find(apItem => apItem.value.includes(formGroup.get(apItem.field).value)));
      }
    }


    return replacedCfg
      ? replacedCfg?.isFullReplace
        ? { ...replacedCfg && { ...replacedCfg.toReplace } }
        : HelperService.deepMerge(field?.elementConfiguration, replacedCfg.toReplace)
      : { ...field?.elementConfiguration};
  }


  /**
   * Validates all form fields in a FormGroup, FormArray, or FormControl.
   *
   * @param {FormGroup | FormArray | any} formGroup - FormGroup, FormArray, or FormControl to validate.
   * @param {boolean} skipValidation - Indicates whether to skip validation for certain fields.
   */
  validateAllFormFields(formGroup: FormGroup | FormArray | any, skipValidation = false) {
    Object.keys(formGroup?.controls ?? {}).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        if (!control['field']().isShow || skipValidation) {
          control.markAsUntouched({onlySelf: true});
          control.setErrors(null);
          this.additionalFormIsInvalid.emit(false);
        } else {
          control.markAsDirty()
          control.markAsTouched();
          control.updateValueAndValidity();
        }
      } else {
        this.validateAllFormFields(control, !control['field']().isShow);
      }
    });
  }
}
