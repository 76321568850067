import {LoadChildren} from '@angular/router';
import {EntityArray} from '@/app/core/entity/entity-array';
import 'reflect-metadata';
import {Type} from 'class-transformer';


export class RouterEntity {
  title?: string;
  icon?: string;
  path?: string;
  canActivateChild?: any[];
  component?: any;
  loadChildren?: LoadChildren;
  data?: {
    permissions?: string[];
  }

  @Type(() => RouterEntity)
  children?: RouterEntity[] = null ;

  redirectTo?: string;
  pathMatch?: string;

  isVisibleInMainMenu?: boolean = false;
  isVisibleInBottomMenu?: boolean = false;

  isInAppContainer?: boolean = true;

  isAccess?() {
    return true;
  }

  toRouter?() {
    const routerData: any = {};
    if (this.path) routerData.path = this.path;
    if (this.loadChildren && !this.redirectTo) {
      routerData.loadChildren = () => this.loadChildren();
    }
    if (this.children && !this.redirectTo) {
      routerData.children = this.children.map(item => item.toRouter());
    }
    if (this.canActivateChild && !this.redirectTo) {
      routerData.canActivateChild = this.canActivateChild;
    }
    if (this.redirectTo && this.pathMatch) {
      routerData.redirectTo = this.redirectTo;
      routerData.pathMatch = this.pathMatch;
    }
    if (this.isInAppContainer) routerData.isInAppContainer = this.isInAppContainer;
    if (this.data) routerData.data = this.data;
    return routerData;
  }

  toMenu?() {
    const menuData: any = {};
    if (this.title) menuData.title = this.title;
    if (this.icon) menuData.icon = this.icon;
    if (this.path) menuData.path = this.path;
    if (this.children) {
      menuData.children = this.children.map(item => item.toMenu());
    }
    if (this.isAccess) {
      menuData.isAccess = () => this.isAccess();
    }
    if (this.isVisibleInMainMenu) menuData.isVisibleInMainMenu = this.isVisibleInMainMenu;
    if (this.isVisibleInBottomMenu) menuData.isVisibleInBottomMenu = this.isVisibleInBottomMenu;
    if (this.data) menuData.data = this.data;

    return menuData;
  }
}

export class RouterList extends EntityArray<RouterEntity>{
  toRouterList?() {
    return this.map(item => item.toRouter());
  }

  toMenuList?(flag?: string) {
    const menuList = this.map(item => item.toMenu());

    if (flag) {
      return menuList.filter(item => item[flag])
    }

    return menuList;
  }
}
