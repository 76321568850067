import {Pagination} from './pagination.entity';
import {Entity} from '../../core/entity/main.entity';

export class ApiResponse extends Entity {
  status = true;
  message = '';
  result: any = null;
  pagination: Pagination | null = null;

  constructor(options: Partial<ApiResponse>) {
    super({});

    Object.assign(this, options);
  }
}
